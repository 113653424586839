import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { getAssetUri } from '../../utilities/assetEndpointUtility';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const BreakpointImageContainer = styled('img')(({ theme }) => ({
	[theme.breakpoints.up('sm')]: {
		height: 200
	},
	[theme.breakpoints.down('sm')]: {
		height: 80
	}
}));

const CenteredLayoutContainer = styled(Container)({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100vh',
	width: '100%',
	backgroundImage: `url(${getAssetUri()}/public/slyce360LoginFlowBackground.png)`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain'
});

const RegistrationPageWrapper = (props) => {
	const { children, t } = props;

	return (
		<CenteredLayoutContainer maxWidth={false}>
			<Stack
				direction={{ md: 'column', lg: 'row-reverse' }}
				gap={5}
				wrap='wrap'
			>
				<Stack direction='column'>
					<Typography variant='h4' ml={5}>
						{t('branding.taglineIntro')}
					</Typography>
					<BreakpointImageContainer
						src={`${getAssetUri()}/public/slyce360LogoWithTagline.svg`}
						alt='Slyce360 - Grab a bigger slyce of the recurring payments pie'
					/>
				</Stack>
				<Stack maxWidth={400}>{children}</Stack>
			</Stack>
		</CenteredLayoutContainer>
	);
};

RegistrationPageWrapper.propTypes = {
	children: PropTypes.node,
	t: PropTypes.func
};

export default withTranslation('registration')(RegistrationPageWrapper);
