import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { authActions } from '../../containers/Auth';

import BorderedPaper from '../branded/BorderedPaper';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const ForgotPasswordForm = (props) => {
	const { hasSentCode, isSendingResetCode, t } = props;

	const [username, setUsername] = React.useState('');

	const navigate = useNavigate();

	React.useEffect(() => {
		if (hasSentCode) {
			navigate('/password-reset', { state: { username } });
		}
	}, [hasSentCode]);

	const handleGoBack = () => {
		navigate('/login');
	};

	const onSubmit = (e) => {
		const { sendResetCode } = props;

		sendResetCode({
			username
		});
	};

	return (
		<BorderedPaper>
			<Stack>
				<Stack
					direction='row'
					justifyContent='flex-start'
					alignItems='center'
					ml={1}
					mt={0.5}
				>
					<Stack>
						<IconButton onClick={handleGoBack}>
							<KeyboardArrowLeftIcon
								sx={{ fontSize: '2rem' }}
								color='primary'
							/>
						</IconButton>
					</Stack>
					<Stack direction='row' my={2} px={4}>
						<BoldLargeHeader>{t('passwordReset.title')}</BoldLargeHeader>
					</Stack>
				</Stack>
				<Box px={4}>
					<Stack p={1} mx={4} spacing={1} mt={2}>
						<FormControl>
							<TextField
								size='small'
								onChange={(e) => setUsername(e.target.value)}
								type='text'
								label={t('passwordReset.input.enterUsername')}
								variant='outlined'
								value={username}
							/>
						</FormControl>
					</Stack>
					<Box my={1} p={2} sx={{ textAlign: 'center' }}>
						<Typography
							className={{
								fontsize: '.8rem',
								fontWeight: 600,
								alignSelf: 'center'
							}}
						>
							{t('passwordReset.verificationText')}
						</Typography>
					</Box>
					<Stack direction='row' justifyContent='center' mb={2} mt={2}>
						<ContainedLoadingButton
							bgcolor='primary.alt'
							loading={isSendingResetCode}
							disabled={username.length < 1}
							onClick={onSubmit}
						>
							{t('passwordReset.sendResetCode')}
						</ContainedLoadingButton>
					</Stack>
				</Box>
			</Stack>
		</BorderedPaper>
	);
};

ForgotPasswordForm.propTypes = {
	hasSentCode: PropTypes.bool,
	isSendingResetCode: PropTypes.bool,
	sendResetCode: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		hasSentCode: state.authReducer.getIn([
			'passwordReset',
			'hasSentResetEmail'
		]),
		isSendingResetCode: state.authReducer.getIn([
			'passwordReset',
			'isSendingResetCode'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendResetCode: (payload) => {
			dispatch(authActions.sendPasswordResetCode(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('registration')(ForgotPasswordForm));
