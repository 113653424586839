import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const StyledPaper = styled(Paper)({
	display: 'flex',
	flexDirection: 'column',
	width: '900px'
});

export const Submit = (props) => {
	const { t, onSubmit, onCancel, isPreviousStageCompleted } = props;

  const {
    isLoading: isAddingGroup,
    error: addGroupError
  } = useSelector(state => state.groupsSlice.addGroup)
  const {
    isLoading: isUpdatingGroup,
    error: updateGroupError
  } = useSelector(state => state.groupsSlice.updateGroup);

	return (
		<StyledPaper>
			<Stack direction='row' spacing={2} justifyContent='flex-end' p={2}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          { addGroupError || updateGroupError
            ? (<Typography color='error'>{addGroupError ?? updateGroupError}</Typography>)
            : null
          }
        </Box>
				<Button variant='outlined' onClick={onCancel}>
					{t('buttons.cancel')}
				</Button>
				<Box sx={{ width: 150 }}>
					<LoadingButton
						variant='contained'
						disabled={!isPreviousStageCompleted}
						onClick={onSubmit}
						fullWidth
            loading={isAddingGroup || isUpdatingGroup}
					>
						{t('buttons.submit')}
					</LoadingButton>
				</Box>
			</Stack>
		</StyledPaper>
	);
};

Submit.propTypes = {
	t: PropTypes.func,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	isPreviousStageCompleted: PropTypes.bool
};

export default withTranslation('common')(Submit);
