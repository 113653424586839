import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	groups: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	group: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	addGroup: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	updateGroup: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	deleteGroup: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const groupsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		getGroups: (state) => {
			state.groups.isLoading = true;
			state.groups.error = undefined;
		},
		getGroupsCompleted: (state, action) => {
			state.groups.isLoading = false;
			state.groups.hasBeenLoaded = true;
			state.groups.data = action.payload;
		},
		getGroupsFailed: (state, action) => {
			state.groups.isLoading = false;
			state.groups.error = action.payload;
		},
		resetGroups: (state) => {
			state.groups = initialState.groups;
		},
		getGroup: (state) => {
			state.group.isLoading = true;
			state.group.error = undefined;
		},
		getGroupCompleted: (state, action) => {
			state.group.isLoading = false;
			state.group.hasBeenLoaded = true;
			state.group.data = action.payload;
		},
		getGroupFailed: (state, action) => {
			state.group.isLoading = false;
			state.group.error = action.payload;
		},
		resetGroup: (state) => {
			state.group = initialState.group;
		},
		addGroup: (state) => {
			state.addGroup.isLoading = true;
			state.addGroup.error = undefined;
		},
		addGroupCompleted: (state, action) => {
			state.addGroup.isLoading = false;
			state.addGroup.hasCompleted = true;
		},
		addGroupFailed: (state, action) => {
			state.addGroup.isLoading = false;
			state.addGroup.error = action.payload;
		},
		resetAddGroup: (state) => {
			state.addGroup = initialState.addGroup;
		},
		updateGroup: (state) => {
			state.updateGroup.isLoading = true;
			state.updateGroup.error = undefined;
		},
		updateGroupCompleted: (state, action) => {
			state.updateGroup.isLoading = false;
			state.updateGroup.hasCompleted = true;
		},
		updateGroupFailed: (state, action) => {
			state.updateGroup.isLoading = false;
			state.updateGroup.error = action.payload;
		},
		resetUpdateGroup: (state) => {
			state.updateGroup = initialState.updateGroup;
		},
		deleteGroup: (state) => {
			state.deleteGroup.isLoading = true;
			state.deleteGroup.error = undefined;
		},
		deleteGroupCompleted: (state, action) => {
			state.deleteGroup.isLoading = false;
			state.deleteGroup.hasCompleted = true;
		},
		deleteGroupFailed: (state, action) => {
			state.deleteGroup.isLoading = false;
			state.deleteGroup.error = action.payload;
		},
		resetDeleteGroup: (state) => {
			state.deleteGroup = initialState.deleteGroup;
		}
	}
});

export const {
	getGroups,
	getGroupsCompleted,
	getGroupsFailed,
	resetGroups,
	getGroup,
	getGroupCompleted,
	getGroupFailed,
	resetGroup,
	addGroup,
	addGroupCompleted,
	addGroupFailed,
	resetAddGroup,
	updateGroup,
	updateGroupCompleted,
	updateGroupFailed,
	resetUpdateGroup,
	deleteGroup,
	deleteGroupCompleted,
	deleteGroupFailed,
	resetDeleteGroup
} = groupsSlice.actions;

export default groupsSlice.reducer;
