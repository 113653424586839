import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { cancelInvitation } from '../../../containers/registration/registrationSlice';
import { usersActions } from '../../../containers/Users';

import OutlinedLoadingButton from '../../buttons/OutlinedLoadingButton';

const PendingUsersActionList = ({ getPendingUsers, invitationId, t }) => {
	const dispatchNew = useDispatch();

	const { isLoading: isCancelling, hasCompleted: hasBeenCancelled } =
		useSelector((state) => state.registrationSlice.cancelInvitation);

	const [selectedInvitationId, setSelectedInvitationId] = React.useState();

	React.useEffect(() => {
		if (hasBeenCancelled && !isCancelling) {
			getPendingUsers();
		}
	}, [hasBeenCancelled, isCancelling]);

	const handleCancelInvitation = () => {
		setSelectedInvitationId(invitationId);
		dispatchNew(
			cancelInvitation({
				status: 'CANCELLED',
				invitationId
			})
		);
	};

	return (
		<OutlinedLoadingButton
			onClick={handleCancelInvitation}
			loading={selectedInvitationId === invitationId && isCancelling}
		>
			<span>{t('buttons.cancel')}</span>
		</OutlinedLoadingButton>
	);
};

PendingUsersActionList.propTypes = {
	getPendingUsers: PropTypes.func,
	invitationId: PropTypes.string,
	t: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPendingUsers: (payload) => {
			dispatch(usersActions.getPendingUsers(payload));
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(withTranslation('common')(PendingUsersActionList));
