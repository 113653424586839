import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { withTranslation } from 'react-i18next';
import { groupBuildTypes } from '../../constants/groupBuildTypes';
import Restricted from '../../../../permissionProvider/Restricted';

import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const GroupTableRow = (props) => {
	const { t, row, onMarkGroupForDeletion } = props;

	const navigate = useNavigate();

	const onEditGroup = (groupId) => {
		navigate(`/groups/${groupId}/manage`);
	};

	return (
		<TableRow key={row.caseId}>
			<TableCell component='th' align='left'>
				{row.abbreviation ? row.abbreviation : '-'}
			</TableCell>
			<TableCell scope='row'>{row.groupLabel}</TableCell>
			<TableCell align='center'>
				{row.itemCount ? row.itemCount : '-'}
			</TableCell>
			<TableCell align='center'>
				{row.type ? t(groupBuildTypes[row.type].labelTranslationPath) : '-'}
			</TableCell>
			<TableCell align='right'>
				<Restricted to='groups.groups.edit'>
					<IconButton onClick={() => onEditGroup(row.id)}>
						<Tooltip placement='top' title={t('groupManager.edit')}>
							<EditIcon />
						</Tooltip>
					</IconButton>
				</Restricted>
			</TableCell>
			<TableCell align='right'>
				<Restricted to='groups.groups.delete'>
					<IconButton
						onClick={() => {
							onMarkGroupForDeletion(row.id);
						}}
					>
						<Tooltip placement='top' title={t('groupManager.delete')}>
							<DeleteIcon />
						</Tooltip>
					</IconButton>
				</Restricted>
			</TableCell>
		</TableRow>
	);
};

GroupTableRow.propTypes = {
	t: PropTypes.func,
	row: PropTypes.object,
	onMarkGroupForDeletion: PropTypes.func
};

export default withTranslation('groups')(GroupTableRow);
