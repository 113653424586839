import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import NotificationRow from './components/NotificationRow';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import SubtextError from '../../errors/SubtextError';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export const NotificationList = ({ t }) => {
	const { data, isLoading, hasBeenLoaded, error } = useSelector(
		(state) => state.notificationsSlice.getNotifications
	);

	return (
		<Stack direction='column' spacing={2} mt={2} px={2}>
			{error ? <SubtextError /> : null}
			{isLoading ? (
				<Stack direction='row' justifyContent='center' py={2}>
					<CircularProgress />
				</Stack>
			) : null}
			{hasBeenLoaded && !isLoading && !error
				? data?.map((notification) => (
						<NotificationRow
							key={notification.id}
							notification={notification}
						/>
					))
				: null}
			{!isLoading && hasBeenLoaded && !error && isDataEmpty(data) ? (
				<Stack direction='row' justifyContent='center'>
					<Typography variant='overline'>
						{t('notifications:noResults')}
					</Typography>
				</Stack>
			) : null}
		</Stack>
	);
};

NotificationList.propTypes = {
	data: PropTypes.array,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	t: PropTypes.func
};

export default withTranslation('notifications')(NotificationList);
