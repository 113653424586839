import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router';

import attachUsernameToCPI from '../../helpers/registration/attachUsernameToCPI';
import newUserDefaultConfiguration from './constants/newUserDefaultConfiguration';
import { formatPhoneNumberDigitsOnly } from '../../utilities/formatPhoneNumber';

import InvitationValidationError from './InvitationValidationError';
import SubmitRegistrationError from './SubmitRegistrationError';
import RegistrationForm from './RegistrationForm';
import InvitationLoader from './InvitationLoader';
import ConfirmationForm from './ConfirmationForm';
import BorderedPaper from '../branded/BorderedPaper';

import {
	submitRegistration,
	validateInvitationId
} from '../../containers/registration/registrationSlice';

export const RegistrationManager = () => {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();

	const [newUserConfiguration, setNewUserConfiguration] = React.useState(
		newUserDefaultConfiguration
	);

	const { error: validationError, result: validatedInvitationId } = useSelector(
		(state) => state.registrationSlice.validateInvitationId
	);

	const {
		isLoading: isSubmitRegistrationLoading,
		hasCompleted: hasSubmitRegistrationCompleted,
		error: submitRegistrationError
	} = useSelector((state) => state.registrationSlice.submitRegistration);

	React.useEffect(() => {
		const clientId = searchParams.get('ACID');
		const invitationId = searchParams.get('invitationId');

		if (clientId && invitationId) {
			dispatch(validateInvitationId({ clientId, invitationId }));
		}
	}, [searchParams]);

	const onSubmit = () => {
		const userPoolId = searchParams.get('UPID');

		attachUsernameToCPI(newUserConfiguration.username);

		dispatch(
			submitRegistration({
				userPoolId,
				username: newUserConfiguration.username.toLocaleLowerCase(),
				firstName: newUserConfiguration.firstName,
				lastName: newUserConfiguration.lastName,
				invitationId: validatedInvitationId,
				phoneNumber: formatPhoneNumberDigitsOnly(newUserConfiguration.phone),
				department: newUserConfiguration.department,
				avatar: newUserConfiguration.avatar
			})
		);
	};

	if (validationError) {
		return (
			<BorderedPaper>
				<InvitationValidationError />
			</BorderedPaper>
		);
	}

	if (submitRegistrationError) {
		return (
			<BorderedPaper>
				<SubmitRegistrationError />
			</BorderedPaper>
		);
	}

	if (!validatedInvitationId) {
		return (
			<BorderedPaper>
				<InvitationLoader
					validatedInvitationId={validatedInvitationId}
					validateInvitationError={validationError}
					registrationError={submitRegistrationError}
				/>
			</BorderedPaper>
		);
	}

	if (hasSubmitRegistrationCompleted || isSubmitRegistrationLoading) {
		return (
			<BorderedPaper>
				<ConfirmationForm
					hasBeenRegistered={hasSubmitRegistrationCompleted}
					isRegistering={isSubmitRegistrationLoading}
				/>
			</BorderedPaper>
		);
	}

	return (
		<BorderedPaper>
			<RegistrationForm
				newUserConfiguration={newUserConfiguration}
				setNewUserConfiguration={setNewUserConfiguration}
				onSubmit={onSubmit}
			/>
		</BorderedPaper>
	);
};

export default RegistrationManager;
