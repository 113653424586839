import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { noCase } from 'change-case';
import isDataEmpty from '../../../../helpers/shared/isDataEmpty';
import TimelineIcon from './TimelineIcon';
import SuggestedActions from './SuggestedActions';
// import Attachments from './Attachments';
import { notificationSeverityLevelsNamed } from '../../../../constants/notificationSeverityLevels';
import { formatDateForDisplay } from '../../../../utilities/dateWrapper';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

const StyledPaper = styled(Paper)({
	display: 'flex',
	flexDirection: 'column',
	padding: '20px',
	maxWidth: '900px'
});

const UpdateDetail = ({ t, detail, notification }) => {
	const color =
		notificationSeverityLevelsNamed[noCase(notification?.severity)]
			.colorThemePath;

	return (
		<TimelineItem sx={{ mt: '20px' }}>
			<TimelineOppositeContent sx={{ flex: 0.1 }}>
				<Typography variant='caption'>
					{formatDateForDisplay(detail.createdOn, 'hh:mm A')}
				</Typography>
			</TimelineOppositeContent>
			<TimelineSeparator>
				<TimelineDot
					variant='outlined'
					sx={{ backgroundColor: 'common.white' }}
				>
					{TimelineIcon(detail.detailType, color)}
				</TimelineDot>
				<TimelineConnector sx={{ minHeight: '40px' }} />
			</TimelineSeparator>
			<TimelineContent>
				<StyledPaper elevation={1}>
					<Typography variant='body1' sx={{ m: '5px 0' }}>
						{detail.notificationDescription
							? detail.notificationDescription
							: detail.message}
					</Typography>
					{/* TODO: This will be reworked and implemented differently */}
					{/* {detail.attachments.length > 0 && (
						<Attachments attachments={detail.attachments} />
					)} */}
					{detail.suggestedActions?.length > 0 ? (
						<SuggestedActions
							triggerId={detail.triggerId}
							suggestedActions={detail.suggestedActions}
							notification={notification}
						/>
					) : null}
					<Stack
						direction='row'
						spacing={1}
						alignItems='center'
						sx={{ m: '5px 0' }}
					>
						{!isDataEmpty(detail.createdByUser) && (
							<React.Fragment>
								<Divider flexItem orientation='vertical' />
								<Typography variant='caption'>
									{t('notifications:createdBy')}
								</Typography>
								<Typography variant='caption'>{`${detail.createdByUser.firstName} ${detail.createdByUser.lastName}`}</Typography>
							</React.Fragment>
						)}
					</Stack>
				</StyledPaper>
			</TimelineContent>
		</TimelineItem>
	);
};

UpdateDetail.propTypes = {
	detail: PropTypes.object,
	notification: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	t: PropTypes.func
};

export default withTranslation('notifications')(UpdateDetail);
