import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { noCasing, capitalCasing } from '../../../utilities/changeCase';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import NotificationSeverityIndicatorBox from '../../shared/NotificationSeverityIndicatorBox';
import Restricted from '../../../permissionProvider/Restricted';
import AssignedUserSelect from '../../notifications/AssignedUserSelect';
import NotificationStatusSelect from '../../notifications/NotificationStatusSelect';
import PageError from '../../errors/PageError';
import { notificationStatusesNamed } from '../../../constants/notificationStatuses';
import NotificationSeverityIcon from '../../shared/NotificationSeverityIcon';
import { ruleBuilderCategoryIcons } from '../../../icons';

import {
	getNotification,
	resetGetNotification,
	updateNotification
} from '../../../containers/notifications/notificationsSlice';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';

import { styled } from '@mui/material/styles';

const CategoryIconBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	marginRight: theme.spacing(2),
	width: 50,
	'& .icon': {
		fontSize: '2rem',
		color: theme.palette.secondary.light,
		opacity: 0.5
	}
}));

const NotificationInformation = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { notificationId } = useParams();

	const [assignedUser, setAssignedUser] = React.useState('none');
	const [status, setStatus] = React.useState('');

	const {
		data: notificationDetail,
		isLoading: isLoadingNotificationDetail,
		hasBeenLoaded: hasLoadedNotificationDetail,
		error: notificationDetailError
	} = useSelector((state) => state.notificationsSlice.getNotification);

	const { isLoading: isUpdatingNotification } = useSelector(
		(state) => state.notificationsSlice.updateNotification
	);

	const user = useSelector((state) => state.userReducer.get('user'));

	React.useEffect(() => {
		if (notificationId) {
			dispatch(
				getNotification({
					notificationId
				})
			);
		}

		return () => {
			dispatch(resetGetNotification());
		};
	}, [notificationId, isUpdatingNotification]);

	React.useEffect(() => {
		if (notificationDetail?.assignedUserId) {
			setAssignedUser(notificationDetail?.assignedUserId);
		}

		if (notificationDetail?.status) {
			setStatus(notificationDetail?.status);
		}
	}, [notificationDetail]);

	const onUpdateNotification = React.useCallback(() => {
		let user;
		if (assignedUser && assignedUser.id) {
			user = assignedUser.id;
		} else {
			user = assignedUser;
		}
		dispatch(
			updateNotification({
				notificationId,
				notification: {
					assignedUserId: user ?? null,
					status
				}
			})
		);
	}, [assignedUser, user, status]);

	if (notificationDetailError) {
		return <PageError />;
	}

	if (!hasLoadedNotificationDetail) {
		return (
			<Stack direction='row' justifyContent='center'>
				<Skeleton height={260} width='100%' variant='rounded' />
			</Stack>
		);
	}

	if (isDataEmpty(notificationDetail) && !notificationDetailError) {
		return (
			<Alert
				sx={{ mb: 3 }}
				severity='warning'
				action={
					<Button
						color='inherit'
						size='small'
						onClick={() => navigate('/notifications')}
					>
						Back to Notifications List
					</Button>
				}
			>
				There is no data available for this selection. Please try your search
				again.
			</Alert>
		);
	}

	const CategoryIcon =
		ruleBuilderCategoryIcons[noCasing(notificationDetail?.category)];
	const initialNotification = notificationDetail?.notificationDetails.find(
		(detail) => {
			return detail.detailType === 'initial';
		}
	);

	return (
		<Stack direction='row' component={Paper}>
			<NotificationSeverityIndicatorBox
				severity={notificationDetail?.severity}
			/>
			<Stack spacing={2} sx={{ width: '100%' }} p={1}>
				<Stack direction='row' px={1} justifyContent='space-between'>
					<Typography variant='h6'>
						{t('notifications:notificationDetails')}
					</Typography>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='left'
						spacing={1}
					>
						<NotificationSeverityIcon severity={notificationDetail?.severity} />
						<Typography variant='body1'>
							{capitalCasing(notificationDetail?.severity)}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction='row' p={1} alignItems='center'>
					<CategoryIconBox>
						<Tooltip title={capitalCasing(t(notificationDetail?.category))}>
							<CategoryIcon className='icon' />
						</Tooltip>
					</CategoryIconBox>
					<Stack>
						<Typography>
							{initialNotification?.notificationDescription}{' '}
						</Typography>
						<Typography variant='caption'>
							{t('notifications:userRule')}{' '}
							<b>{initialNotification?.userRuleDescription}</b>
						</Typography>
					</Stack>
				</Stack>
				<Restricted to='notifications.notificationDetail.edit'>
					<Stack>
						<Stack direction='row' gap={2} flexWrap='wrap' my={2}>
							<Stack flex={1}>
								<AssignedUserSelect
									selectedUser={assignedUser}
									onChangeSelection={setAssignedUser}
									overrideLabel={t('notifications:assignedUser')}
									includeAll={false}
									isDisabled={
										isLoadingNotificationDetail ||
										notificationDetail?.status ===
											notificationStatusesNamed.closed.id ||
										notificationDetail?.status ===
											notificationStatusesNamed.resolved.id
									}
								/>
							</Stack>
							<Stack flex={1}>
								<NotificationStatusSelect
									selectedNotificationStatus={status}
									onChangeSelection={setStatus}
									excludeAll={true}
									isDisabled={isLoadingNotificationDetail}
								/>
							</Stack>
						</Stack>
						<Stack alignItems='flex-end'>
							<LoadingButton
								loading={isUpdatingNotification}
								variant='contained'
								onClick={onUpdateNotification}
								size='small'
							>
								Update
							</LoadingButton>
						</Stack>
					</Stack>
				</Restricted>
			</Stack>
		</Stack>
	);
};

NotificationInformation.propTypes = {
	t: PropTypes.func,
	notification: PropTypes.object,
	users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	user: PropTypes.object,
	onUpdateNotification: PropTypes.func,
	onAddNotificationDetail: PropTypes.func
};

export default withTranslation('notifications')(NotificationInformation);
