import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	mids: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const midsSlice = createSlice({
	name: 'mids',
	initialState,
	reducers: {
		getMids: (state) => {
			state.mids.isLoading = true;
			state.mids.error = undefined;
		},
		getMidsCompleted: (state, action) => {
			state.mids.isLoading = false;
			state.mids.hasBeenLoaded = true;
			state.mids.data = action.payload;
		},
		getMidsFailed: (state, action) => {
			state.mids.isLoading = false;
			state.mids.error = action.payload;
		},
		resetMids: (state) => {
			state.mids = initialState.mids;
		}
	}
});

export const {
	getMids,
	getMidsCompleted,
	getMidsFailed,
	resetMids
} = midsSlice.actions;

export default midsSlice.reducer;
