import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { withTranslation } from 'react-i18next';

import { modalActions } from '../../../containers/Modal';
import {
	multiFactorAuthActions,
	multiFactorAuthConstants
} from '../../../containers/MultiFactorAuth';
import SetupPage from './components/SetupPage';
import SuccessPage from './components/SuccessPage';
import ModifyPage from './components/ModifyPage';
import GenericModalPaperWrapper from '../GenericModalPaperWrapper';

import HeaderRow from '../shared/HeaderRow';

import Stack from '@mui/material/Stack';

export const MultiFactorAuthSetupModal = (props) => {
	const {
		user,
		isMultiFactorAuthSetupModalOpen,
		userPreferredMFA,
		mfaSetupError
	} = props;

	const location = useLocation();
	const navigate = useNavigate();

	const modalFunction = location.pathname.split('/')[2];

	const onCloseModal = () => {
		const { closeModal } = props;

		closeModal();
		navigate('/account');
	};

	const onActivateMfa = (user, challengeAnswer) => {
		const { activateMultiFactorAuth } = props;

		activateMultiFactorAuth({
			user,
			challengeAnswer
		});
	};

	const onDisableMfa = (user, challengeAnswer) => {
		const { disableMultiFactorAuth, closeModal } = props;

		disableMultiFactorAuth({
			user,
			challengeAnswer
		});

		closeModal();
		navigate('/account');
	};

	return (
		<GenericModalPaperWrapper
			open={isMultiFactorAuthSetupModalOpen}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<Stack p={2} spacing={1}>
				<HeaderRow
					translationPath={'multiFactorAuth.multiFactorAuthSetup'}
					onClickCancel={onCloseModal}
				/>
				{modalFunction === 'deactivate-mfa' ? (
					<ModifyPage
						user={user}
						onCloseModal={onCloseModal}
						onDisableMfa={onDisableMfa}
						mfaSetupError={mfaSetupError}
					/>
				) : null}
				{modalFunction === 'activate-mfa' &&
				userPreferredMFA === multiFactorAuthConstants.softwareMfa ? (
						<SuccessPage
							onCloseModal={onCloseModal}
							userPreferredMfa={userPreferredMFA}
						/>
					) : null}
				{modalFunction === 'activate-mfa' &&
				userPreferredMFA !== multiFactorAuthConstants.softwareMfa ? (
						<SetupPage
							user={user}
							onActivateMfa={onActivateMfa}
							mfaSetupError={mfaSetupError}
							onCloseModal={onCloseModal}
						/>
					) : null}
			</Stack>
		</GenericModalPaperWrapper>
	);
};

MultiFactorAuthSetupModal.propTypes = {
	isMultiFactorAuthSetupModalOpen: PropTypes.bool,
	user: PropTypes.object,
	closeModal: PropTypes.func,
	activateMultiFactorAuth: PropTypes.func,
	disableMultiFactorAuth: PropTypes.func,
	userPreferredMFA: PropTypes.string,
	mfaSetupError: PropTypes.string,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isMultiFactorAuthSetupModalOpen: state.modalReducer.get(
			'isMultiFactorAuthSetupModalOpen'
		),
		userPreferredMFA: state.multiFactorAuthReducer.get('preferredMFA'),
		mfaSetupError: state.multiFactorAuthReducer.get('erorr'),
		user: state.authReducer.get('user')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: () => {
			dispatch(modalActions.toggleMultiFactorAuthSetupModal());
		},
		activateMultiFactorAuth: (payload) => {
			dispatch(multiFactorAuthActions.activateMultiFactorAuth(payload));
		},
		disableMultiFactorAuth: (payload) => {
			dispatch(multiFactorAuthActions.disableMultiFactorAuth(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('modals')(MultiFactorAuthSetupModal));
