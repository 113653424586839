import React from 'react';

const defaultBehavior = {
	currentPage: 1,
	selectedRuleCategory: 'all',
	selectedLevel: 'all',
	getUserRules: () => {}
};

const SavedRulesContext = React.createContext(defaultBehavior);

export default SavedRulesContext;
