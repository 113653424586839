import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import * as actionTypes from './actionTypes';
import * as actions from './actions';

export const getUsersEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_USERS),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway()}/api/users`);

			return response?.data;
		}),
		switchMap((res) => [actions.getUsersCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getUsersFailed(error.message)), source)
		)
	);

export const getPendingUsersEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.GET_PENDING_USERS),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway(true)}/users/pending`
			);

			return response?.data;
		}),
		switchMap((res) => [actions.getPendingUsersCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.getPendingUsersFailed(error.message)), source)
		)
	);

export const inviteUserEpic = (action$) =>
	action$.pipe(
		ofType(actionTypes.INVITE_USER),
		mergeMap(async (action) => {
			await refreshToken();

			const { email } = action.payload;

			await axios.post(`${primaryRestGateway(true)}/users/invite`, {
				email
			});

			return [];
		}),
		switchMap((res) => [actions.inviteUserCompleted(res)]),
		catchError((error, source) =>
			merge(of(actions.inviteUserFailed(error.message)), source)
		)
	);
