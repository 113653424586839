import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import SavedRulesContext from './SavedRulesContext';

import { getUserRules, resetUserRules } from '../../../containers/userRules/userRulesSlice';
import { getRuleRunToken, resetRuleRunToken } from '../../../containers/RulesRunner/rulesRunnerTokenSlice';
import { runUntrackedUserRule, resetRunUntrackedUserRule } from '../../../containers/RulesRunner/rulesRunnerUntrackedSlice';

const CLEAR_RULE_RUN_RESULTS_MS = 5000;

const SavedRulesProvider = ({ children }) => {
	const dispatch = useDispatch();

	const [selectedRuleCategory, setSelectedRuleCategory] = React.useState('all');
	const [selectedLevel, setSelectedLevel] = React.useState('all');
	const [selectedApplyTo, setSelectedApplyTo] = React.useState('all');

	const refreshTokenTimeoutRef = React.useRef(null);
	const resetRuleRunTimeoutRef = React.useRef(null);

	const {
		currentPage
	} = useSelector(state => state.userRulesSlice.userRules);
	const {
		ruleRunToken,
		ruleRunTokenExpiration
	} = useSelector(state => state.rulesRunnerTokenSlice);
	const {
		runningUserRuleCompleted,
		runningUserRuleError
	} = useSelector(state => state.rulesRunnerUntrackedSlice);

	React.useEffect(() => {
		ongetUserRules(1);

		return () => {
			dispatch(resetUserRules());
			dispatch(resetRuleRunToken());
		};
	}, []);

	React.useEffect(() => {
		if (!ruleRunToken) {
			dispatch(getRuleRunToken());
		} else if (ruleRunTokenExpiration) {
			window.clearTimeout(refreshTokenTimeoutRef.current);

			const currentDayjs = dayjs();
			const expirtationDayjs = dayjs(ruleRunTokenExpiration);

			const duration = dayjs.duration(expirtationDayjs.diff(currentDayjs));

			refreshTokenTimeoutRef.current = setTimeout(() => getRuleRunToken(), duration.as('milliseconds'));
		}
	}, [ruleRunToken]);

	React.useEffect(() => {
		if (runningUserRuleCompleted || runningUserRuleError) {
			resetRuleRunTimeoutRef.current = setTimeout(() => dispatch(resetRunUntrackedUserRule()), CLEAR_RULE_RUN_RESULTS_MS);
		}
	}, [runningUserRuleCompleted, runningUserRuleError]);

	const onRunUntrackedUserRule = (userRuleId) => {
		dispatch(runUntrackedUserRule({
			userRuleId,
			ruleRunToken
		}));
		resetRuleRunTimeoutRef.current = null;
	};

	const ongetUserRules = (newPage) => {
		dispatch(getUserRules({
			queryParameterString: {
				severity: selectedLevel?.name,
				category: selectedRuleCategory?.name,
				ruleListType: selectedApplyTo?.id,
				resultsPerPage: 10,
				page: newPage
			}
		}));
	};

	const onClickApply = (newPage) => {
		ongetUserRules(newPage || 1);
	};

	const onChangePage = (page) => {
		ongetUserRules(page);
	};

	const resetFilters = () => {
		setSelectedRuleCategory('all');
		setSelectedLevel('all');
		setSelectedApplyTo('all');
	};

	const value = React.useMemo(() => ({
		currentPage,
		onChangePage,
		selectedRuleCategory,
		setSelectedRuleCategory,
		selectedLevel,
		setSelectedLevel,
		selectedApplyTo,
		setSelectedApplyTo,
		getUserRules: onClickApply,
		onRunUntrackedUserRule,
		resetFilters
	}), [
		currentPage,
		onChangePage,
		ongetUserRules,
		selectedRuleCategory,
		selectedLevel,
		selectedApplyTo,
		ruleRunToken,
		ruleRunTokenExpiration
	]);

	return (
		<SavedRulesContext.Provider value={value}>
			{children}
		</SavedRulesContext.Provider>
	);
};

SavedRulesProvider.propTypes = {
	children: PropTypes.element
};

export default SavedRulesProvider;
