import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	getNotifications: {
		data: [],
		currentPage: 1,
		availablePageCount: 0,
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	getNotification: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	updateNotification: {
		result: undefined,
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	getNotificationTimeline: {
		data: {},
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	},
	addNotificationTimelineEvent: {
		result: undefined,
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		getNotifications: (state) => {
			state.getNotifications.isLoading = true;
			state.getNotifications.error = undefined;
		},
		getNotificationsCompleted: (state, action) => {
			state.getNotifications.isLoading = false;
			state.getNotifications.hasBeenLoaded = true;
			state.getNotifications.data = action.payload.results;
			state.getNotifications.availablePageCount =
				action.payload.availablePageCount;
			state.getNotifications.currentPage = action.payload.page;
		},
		getNotificationsFailed: (state, action) => {
			state.getNotifications.isLoading = false;
			state.getNotifications.error = action.payload;
		},
		clearNotifications: (state) => {
			state.getNotifications = initialState.getNotifications;
		},
		getNotification: (state) => {
			state.getNotification.isLoading = true;
			state.getNotification.error = undefined;
		},
		getNotificationCompleted: (state, action) => {
			state.getNotification.isLoading = false;
			state.getNotification.hasBeenLoaded = true;
			state.getNotification.data = action.payload;
		},
		getNotificationFailed: (state, action) => {
			state.getNotification.error = action.payload;
			state.getNotification.isLoading = false;
		},
		resetGetNotification: (state) => {
			state.getNotification = initialState.getNotification;
		},
		updateNotification: (state) => {
			state.updateNotification.isLoading = true;
			state.updateNotification.error = undefined;
		},
		updateNotificationCompleted: (state, action) => {
			state.updateNotification.isLoading = false;
			state.updateNotification.hasCompleted = true;
			state.updateNotification.result = action.payload;
		},
		updateNotificationFailed: (state, action) => {
			state.updateNotification.isLoading = false;
			state.updateNotification.error = action.payload;
		},
		resetUpdateNotification: (state) => {
			state.updateNotification = initialState.updateNotification;
		},
		getNotificationTimeline: (state) => {
			state.getNotificationTimeline.isLoading = true;
			state.getNotificationTimeline.error = undefined;
		},
		getNotificationTimelineCompleted: (state, action) => {
			state.getNotificationTimeline.isLoading = false;
			state.getNotificationTimeline.hasBeenLoaded = true;
			state.getNotificationTimeline.result = action.payload;
		},
		getNotificationTimelineFailed: (state, action) => {
			state.getNotificationTimeline.error = action.payload;
			state.getNotificationTimeline.isLoading = false;
		},
		addNotificationTimelineEvent: (state) => {
			state.addNotificationTimelineEvent.isLoading = true;
			state.addNotificationTimelineEvent.error = undefined;
		},
		addNotificationTimelineEventCompleted: (state, action) => {
			state.addNotificationTimelineEvent.isLoading = false;
			state.addNotificationTimelineEvent.hasCompleted = true;
			state.addNotificationTimelineEvent.result = action.payload;
		},
		addNotificationTimelineEventFailed: (state, action) => {
			state.addNotificationTimelineEvent.isLoading = false;
			state.addNotificationTimelineEvent.error = action.payload;
		},
		resetAddNotificationTimelineEvent: (state, action) => {
			state.addNotificationTimelineEvent =
				initialState.addNotificationTimelineEvent;
		}
	}
});

export const {
	getNotifications,
	getNotificationsCompleted,
	getNotificationsFailed,
	clearNotifications,
	getNotification,
	getNotificationCompleted,
	getNotificationFailed,
	resetGetNotification,
	updateNotification,
	updateNotificationCompleted,
	updateNotificationFailed,
	resetUpdateNotification,
	getNotificationTimeline,
	getNotificationTimelineCompleted,
	getNotificationTimelineFailed,
	addNotificationTimelineEvent,
	addNotificationTimelineEventCompleted,
	addNotificationTimelineEventFailed,
	resetAddNotificationTimelineEvent
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
