import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../buttons/ContainedButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ConfirmationForm = (props) => {
	const { t, deAuthUser } = props;

	return (
		<Box>
			<Stack direction='row' justifyContent='center' my={2}>
				<BoldLargeHeader>{t('passwordReset.success')}</BoldLargeHeader>
			</Stack>
			<Box p={2} mx={1}>
				<Typography variant='body1'>{t('passwordReset.completed')}</Typography>
			</Box>
			<Stack direction='row' justifyContent='center' mb={3}>
				<ContainedButton bgcolor='primary.alt' onClick={deAuthUser}>
					{t('passwordReset.backToLogin')}
				</ContainedButton>
			</Stack>
		</Box>
	);
};

ConfirmationForm.propTypes = {
	deAuthUser: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('registration')(ConfirmationForm);
