import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getUserRules,
	getUserRulesCompleted,
	getUserRulesFailed,
  getUserRule,
	getUserRuleCompleted,
	getUserRuleFailed,
  updateUserRule,
  updateUserRuleCompleted,
  updateUserRuleFailed
} from './userRulesSlice';

export const getUserRulesEpic = (action$) =>
	action$.pipe(
		ofType(getUserRules),
		mergeMap(async (action) => {
			await refreshToken();

			const { queryParameterString } = action.payload;

			const response = await axios.get(`${primaryRestGateway(true)}/user-rules`, {
				params: queryParameterString
			});

			return response.data;
		}),
		switchMap((res) => [getUserRulesCompleted(res)]),
		catchError((error, source) =>
			merge(of(getUserRulesFailed(error.message)), source)
		)
	);

export const getUserRuleEpic = (action$) =>
    action$.pipe(
      ofType(getUserRule),
      mergeMap(async (action) => {
        await refreshToken();
  
        const { userRuleId } = action.payload;
  
        const response = await axios.get(
          `${primaryRestGateway(true)}/user-rules/${userRuleId}`);
  
        return response.data;
      }),
      switchMap((res) => [getUserRuleCompleted(res)]),
      catchError((error, source) =>
        merge(of(getUserRuleFailed(error.message)), source)
      )
    );

export const updateUserRuleEpic = (action$) =>
    action$.pipe(
      ofType(updateUserRule),
      mergeMap(async (action) => {
        await refreshToken();
  
        const {
          userRuleId,
          updatedRuleConfiguration
        } = action.payload;
  
        const response = await axios.put(`${primaryRestGateway(true)}/user-rules/${userRuleId}`, 
          updatedRuleConfiguration
        );
  
        return response.data;
      }),
      switchMap((res) => [updateUserRuleCompleted(res)]),
      catchError((error, source) =>
        merge(of(updateUserRuleFailed(error.message)), source)
      )
    );

export default [
	getUserRulesEpic,
  getUserRuleEpic,
  updateUserRuleEpic
];
