import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { mapGroupItemValues } from '../shared/mapGroupItems';
import defaultGroupConfiguration from '../constants/defaultGroupConfiguration';
import GroupWizard from '../GroupWizard';
import { getCurrentTimestamp } from '../../../utilities/dateWrapper';

import {
	addGroup,
	resetAddGroup
} from '../../../containers/groups/groupsSlice';

export const CreateGroup = ({ user }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [groupConfiguration, setGroupConfiguration] = React.useState(
		defaultGroupConfiguration
	);

	const { hasCompleted: successfullyAddedGroup } = useSelector(
		(state) => state.groupsSlice.addGroup
	);

	React.useEffect(() => {
		if (successfullyAddedGroup) {
			dispatch(resetAddGroup());
			navigate('/groups');
		}
	}, [successfullyAddedGroup]);

	const onCancel = () => {
		dispatch(resetAddGroup());
		navigate('/groups');
	};

	const onSubmit = () => {
		const groupDefinition = {
			groupLabel: groupConfiguration.groupLabel,
			abbreviation: groupConfiguration.abbreviation,
			type: groupConfiguration.buildType?.id,
			userId: user.id,
			createdOn: getCurrentTimestamp(),
			groupItems: groupConfiguration.groupItems
		};

		dispatch(
			addGroup({
				groupDefinition
			})
		);
	};

	return (
		<GroupWizard
			groupConfiguration={groupConfiguration}
			setGroupConfiguration={setGroupConfiguration}
			onSubmit={onSubmit}
			onCancel={onCancel}
		/>
	);
};

CreateGroup.propTypes = {
	addGroup: PropTypes.func,
	user: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user')
	};
};

export default connect(mapStateToProps, null)(CreateGroup);
