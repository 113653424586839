import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const NavigationItem = ({ label, linkTo, alternativeActiveLink, useExact }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onClickNavigationItem = () => {
		navigate(linkTo);
	};

	const isPathActive = (linkTo, alternativeActiveLink, useExact) => {
		if (useExact) {
			return location.pathname === linkTo || location.pathname === alternativeActiveLink;
		} else {
			return location.pathname.includes(linkTo) || location.pathname.includes(alternativeActiveLink);
		}
	};

	const isActive = isPathActive(linkTo, alternativeActiveLink, useExact);

	return (
		<MenuItem
			onClick={onClickNavigationItem}
			selected={isActive}
		>
			<ListItemText>
				<Typography>
					{label}
				</Typography>
			</ListItemText>
		</MenuItem>
	);
};

NavigationItem.propTypes = {
	label: PropTypes.string,
	linkTo: PropTypes.string,
	alternativeActiveLink: PropTypes.string,
	useExact: PropTypes.bool
};

export default NavigationItem;
