import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import StageAccordion from '../StageAccordion';
import AfilliateSelect from '../../../affiliates/AfilliateSelect';
import MidSelect from '../../../merchants/MidSelect';
import ProcessorSelect from '../../../processors/ProcessorSelect';
import MerchantCategoryCodeSelect from '../../../merchants/MerchantCategoryCodeSelect';
import { groupBuildTypes } from '../../constants/groupBuildTypes';

import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

export const GroupItemsSelect = (props) => {
	const {
		t,
		isStageCompleted,
		completedLabel,
		isPreviousStageCompleted,
		selectedBuildType,
		groupItems,
		setGroupItems,
		temporaryGroupValues,
		setTemporaryGroupValues
	} = props;

	const [isStageExpanded, setIsStageExpanded] = React.useState(true);
	const [buildType, setBuildType] = React.useState();
	const [listItemsIndividually, setListItemsIndividually] = React.useState(
		false
	);

	React.useEffect(() => {
		if (selectedBuildType) {
			setBuildType(selectedBuildType);
		}

		if (buildType && buildType?.id !== selectedBuildType?.id) {
			setGroupItems([]);
			setBuildType(selectedBuildType);
		}
	}, [buildType, selectedBuildType]);

	if (!isPreviousStageCompleted) {
		return (
			<StageAccordion
				stageLabel={t('groupWizard.applyTo.addToGroup')}
				collapsedSummaryText={''}
				isAccordionExpanded={false}
				setisAccordionExpanded={() => {}}
				isCompletedFlag={false}
				isDisabled={true}
			/>
		);
	}

	const handleIndividualItemsSwitch = () => {
		setListItemsIndividually(!listItemsIndividually);
	};

	const renderGroupItemsSelect = (buildTypeId, groupItems, setGroupItems) => {
		if (buildTypeId === groupBuildTypes.contract.id) {
			return (
				<AfilliateSelect
					value={groupItems}
					onChange={setGroupItems}
					setTemporaryGroupValues={setTemporaryGroupValues}
					temporaryGroupValues={temporaryGroupValues}
					listItemsIndividually={listItemsIndividually}
				/>
			);
		} else if (buildTypeId === groupBuildTypes.paymentEnablerAcquiringBank.id) {
			return (
				<ProcessorSelect
					value={groupItems}
					onChange={setGroupItems}
					setTemporaryGroupValues={setTemporaryGroupValues}
					temporaryGroupValues={temporaryGroupValues}
					listItemsIndividually={listItemsIndividually}
          isMultiSelect={true}
				/>
			);
		} else if (buildTypeId === groupBuildTypes.merchantCategory.id) {
			return (
				<MerchantCategoryCodeSelect
					value={groupItems}
					onChange={setGroupItems}
					setTemporaryGroupValues={setTemporaryGroupValues}
					temporaryGroupValues={temporaryGroupValues}
					listItemsIndividually={listItemsIndividually}
				/>
			);
		} else {
			return (
				<MidSelect
					value={groupItems}
					onChange={setGroupItems}
					setTemporaryGroupValues={setTemporaryGroupValues}
					temporaryGroupValues={temporaryGroupValues}
					listItemsIndividually={listItemsIndividually}
				/>
			);
		}
	};

	return (
		<StageAccordion
			stageLabel={t('groupWizard.applyTo.addToGroup')}
			collapsedSummaryText={completedLabel}
			isAccordionExpanded={isStageExpanded}
			setisAccordionExpanded={setIsStageExpanded}
			isCompletedFlag={isStageCompleted}
			isDisabled={false}
		>
			<Stack>
				<Box sx={{ width: '100%', display: 'flex' }}>
					{renderGroupItemsSelect(
						selectedBuildType?.id || selectedBuildType,
						groupItems,
						setGroupItems
					)}
				</Box>
				<FormGroup sx={{ mt: 1, pl: 1 }}>
					<FormControlLabel
						control={
							<Switch
								onChange={handleIndividualItemsSwitch}
								disabled={groupItems?.length < 1}
							/>
						}
						label={t('groupWizard.displayIndividually')}
					/>
				</FormGroup>
			</Stack>
		</StageAccordion>
	);
};

GroupItemsSelect.propTypes = {
	t: PropTypes.func,
	isStageCompleted: PropTypes.bool,
	completedLabel: PropTypes.string,
	isPreviousStageCompleted: PropTypes.bool,
	selectedBuildType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	groupItems: PropTypes.array,
	setGroupItems: PropTypes.func,
	temporaryGroupValues: PropTypes.array,
	setTemporaryGroupValues: PropTypes.func
};

export default withTranslation('groups')(GroupItemsSelect);
