import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Auth } from 'aws-amplify';
import { authActions } from '../../containers/Auth';
import { multiFactorAuthActions } from '../../containers/MultiFactorAuth';
import { userActions } from '../../containers/User';

import BorderedPaper from '../branded/BorderedPaper';
import PasswordSetupForm from './PasswordSetupForm';
import ConfirmationForm from './ConfirmationForm';
import PasswordSetupError from './PasswordSetupError';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import BoldLargeHeader from '../headers/BoldLargeHeader';

const PasswordSetupManager = (props) => {
	const {
		authUser,
		isSettingUpPassword,
		hasCompletedPasswordSetup,
		completePasswordSetup,
		passwordSetupError,
		userHasBeenLoaded,
		deAuthUser,
		mfaLogout,
		clearUser,
		t
	} = props;

	const [newUserPassword, setNewUserPassword] = React.useState('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
	const [doPasswordsMatch, setDoPasswordsMatch] = React.useState(true);

	const navigate = useNavigate();

	const deAuthorizeUser = () => {
		Auth.signOut();
		mfaLogout();
		deAuthUser();
		clearUser();

		navigate('/login');
	};

	React.useEffect(() => {
		if (!authUser?.username) {
			deAuthorizeUser();
		}
	}, [authUser]);

	const onSubmitPasswordSetup = () => {
		if (newUserPassword !== confirmNewPassword) {
			setDoPasswordsMatch(false);
		} else {
			setDoPasswordsMatch(true);

			completePasswordSetup({
				newPassword: newUserPassword,
				user: authUser
			});
		}
	};

	const onOnHandleGoBack = () => {
		deAuthorizeUser();
	};

	if (passwordSetupError) {
		return (
			<BorderedPaper>
				<PasswordSetupError />
			</BorderedPaper>
		);
	}

	return (
		<BorderedPaper>
			<Stack
				direction='row'
				justifyContent='flex-start'
				alignItems='center'
				ml={1}
				mt={0.5}
			>
				<Stack>
					<IconButton onClick={onOnHandleGoBack}>
						<KeyboardArrowLeftIcon sx={{ fontSize: '2rem' }} color='primary' />
					</IconButton>
				</Stack>
				<Stack direction='row' justifyContent='center' my={2}>
					<BoldLargeHeader>
						{t('registration:passwordSetup.pageTitle')}
					</BoldLargeHeader>
				</Stack>
			</Stack>
			{!hasCompletedPasswordSetup && !isSettingUpPassword ? (
				<PasswordSetupForm
					doPasswordsMatch={doPasswordsMatch}
					isSettingUpPassword={isSettingUpPassword}
					newUserPassword={newUserPassword}
					setNewUserPassword={setNewUserPassword}
					confirmNewPassword={confirmNewPassword}
					setConfirmNewPassword={setConfirmNewPassword}
					onSubmitPasswordSetup={onSubmitPasswordSetup}
				/>
			) : (
				<ConfirmationForm
					hasCompletedPasswordSetup={hasCompletedPasswordSetup}
					userHasBeenLoaded={userHasBeenLoaded}
				/>
			)}
		</BorderedPaper>
	);
};

PasswordSetupManager.propTypes = {
	authUser: PropTypes.object,
	isSettingUpPassword: PropTypes.bool,
	hasCompletedPasswordSetup: PropTypes.bool,
	completePasswordSetup: PropTypes.func,
	passwordSetupError: PropTypes.string,
	userHasBeenLoaded: PropTypes.bool,
	deAuthUser: PropTypes.func,
	mfaLogout: PropTypes.func,
	clearUser: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		authUser: state.authReducer.get('user'),
		userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded'),
		isSettingUpPassword: state.authReducer.getIn([
			'passwordSetup',
			'isLoading'
		]),
		hasCompletedPasswordSetup: state.authReducer.getIn([
			'passwordSetup',
			'hasBeenLoaded'
		]),
		passwordSetupError: state.authReducer.getIn(['passwordSetup', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		completePasswordSetup: (payload) => {
			dispatch(authActions.completePasswordSetup(payload));
		},
		deAuthUser: (payload) => {
			dispatch(authActions.deAuthUser(payload));
		},
		mfaLogout: () => {
			dispatch(multiFactorAuthActions.logout());
		},
		clearUser: () => {
			dispatch(userActions.logout());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('registration')(PasswordSetupManager));
