import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MerchantTooltip from '../MerchantTooltip';
import keyCodes from '../../../constants/keyCodes';
import SubtextError from '../../errors/SubtextError';

import isDataEmpty from '../../../helpers/shared/isDataEmpty';

import { getMids, resetMids } from '../../../containers/merchants/midsSlice';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const StyledListItem = styled(ListItem)(({ theme }) => ({
	marginLeft: '3px',
	marginTop: '4px',
	marginBottom: '4px',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.palette.reports.filters.midselectHightlight,
		color: theme.palette.primary.main
	}
}));

const MidSelect = ({ t, onChange, value, listItemsIndividually }) => {
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);

	const {
		data: mids,
		isLoading: midsAreLoading,
		hasBeenLoaded: midsHaveBeenLoaded,
		error: midsError
	} = useSelector((state) => state.midsSlice.mids);

	React.useEffect(() => {
		dispatch(getMids());

		return () => {
			return dispatch(resetMids());
		};
	}, []);

	React.useEffect(() => {
		if (open && midsHaveBeenLoaded) {
			setOptions(mids ?? []);
		}
	}, [open, mids]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<FormControl sx={{ flex: 1 }}>
			{midsError ? (
				<Box pl={1}>
					<SubtextError errorText={t('inputs:unableToLoadMids')} />
				</Box>
			) : null}
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={midsAreLoading}
				disabled={!midsHaveBeenLoaded || midsAreLoading}
				isOptionEqualToValue={(option, item) => {
					return option?.midId === item?.midId;
				}}
				getOptionLabel={(option) => option?.midLabel}
				renderTags={
					!listItemsIndividually
						? (value) => (
								<Tooltip title={<MerchantTooltip value={value} />}>
									<Chip variant='outlined' label={`${value.length} selected`} />
								</Tooltip>
							)
						: undefined
				}
				renderOption={(props, option) => {
					return (
						<StyledListItem {...props} key={option.midId}>
							<Stack direction='column'>
								<Typography sx={{ fontWeight: 600 }}>
									{option.midLabel}
								</Typography>
								<Typography variant='caption'>{`${option.paymentEnablerAcquiringBankLabel} - ${option.fma}`}</Typography>
							</Stack>
						</StyledListItem>
					);
				}}
				value={value}
				onChange={(event, newValue, reason) => {
					if (
						reason !== 'removeOption' ||
						event.keyCode !== keyCodes.backspace
					) {
						onChange(newValue);
					}
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ ml: 1, flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t('inputs:selectMerchant')}
						variant='outlined'
						helperText={t('inputs:ctrlHelperText')}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{midsAreLoading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

MidSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.array,
	t: PropTypes.func,
	temporaryGroupValues: PropTypes.array,
	setTemporaryGroupValues: PropTypes.func,
	listItemsIndividually: PropTypes.bool
};

export default withTranslation(['inputs'])(MidSelect);
