import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	userRules: {
		data: [],
		currentPage: 1,
		availablePageCount: 0,
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	},
  userRule: {
    data: {},
    isLoading: false,
    hasBeenLoaded: false,
    error: undefined
  },
  updateUserRule: {
    isLoading: false,
    hasCompleted: false,
    error: undefined
  }
};

export const userRulesSlice = createSlice({
	name: 'userRules',
	initialState,
	reducers: {
		getUserRules: (state) => {
			state.userRules.isLoading = true;
			state.userRules.error = undefined;
		},
		getUserRulesCompleted: (state, action) => {
			state.userRules.isLoading = false;
			state.userRules.hasBeenLoaded = true;
			state.userRules.data = action.payload.rules;
			state.userRules.availablePageCount = action.payload.availablePageCount;
			state.userRules.currentPage = action.payload.page;
		},
		getUserRulesFailed: (state, action) => {
			state.userRules.isLoading = true;
			state.userRules.error = action.payload;
		},
		resetUserRules: (state) => {
			state.userRules = initialState.userRules;
		},
    getUserRule: (state) => {
			state.userRule.isLoading = true;
			state.userRule.error = undefined;
		},
		getUserRuleCompleted: (state, action) => {
			state.userRule.isLoading = false;
			state.userRule.hasBeenLoaded = true;
			state.userRule.data = action.payload;
		},
		getUserRuleFailed: (state, action) => {
			state.userRule.isLoading = false;
			state.userRule.error = action.payload;
		},
		resetUserRule: (state) => {
			state.userRule = initialState.userRule;
		},
    updateUserRule: (state) => {
			state.updateUserRule.isLoading = true;
			state.updateUserRule.error = undefined;
		},
		updateUserRuleCompleted: (state, action) => {
			state.updateUserRule.isLoading = false;
			state.updateUserRule.hasCompleted = true;
		},
		updateUserRuleFailed: (state, action) => {
			state.updateUserRule.isLoading = false;
			state.updateUserRule.error = action.payload;
		},
		resetUpdateUserRule: (state) => {
			state.updateUserRule = initialState.updateUserRule;
		}
	}
});

export const {
	getUserRules,
	getUserRulesCompleted,
	getUserRulesFailed,
	resetUserRules,
  getUserRule,
  getUserRuleCompleted,
  getUserRuleFailed,
  resetUserRule,
  updateUserRule,
  updateUserRuleCompleted,
  updateUserRuleFailed,
  resetUpdateUserRule
} = userRulesSlice.actions;

export default userRulesSlice.reducer;
