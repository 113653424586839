import React from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import deepCopy from '../../../utilities/deepCopy';
import { mapGroupItemsForUpdate } from '../shared/mapGroupItems';
import PageError from '../../errors/PageError';
import GroupWizard from '../GroupWizard';
import { getCurrentTimestamp } from '../../../utilities/dateWrapper';
import defaultGroupConfiguration from '../constants/defaultGroupConfiguration';
import { groupBuildTypes } from '../constants/groupBuildTypes';

import {
	getGroup,
	resetGroup,
	updateGroup,
	resetUpdateGroup
} from '../../../containers/groups/groupsSlice';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export const EditGroup = ({ t, user }) => {
	const { groupId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [groupConfiguration, setGroupConfiguration] = React.useState();
	const [temporaryGroupValues, setTemporaryGroupValues] = React.useState([]);

	const {
		data: group,
		isLoading: groupIsLoading,
		hasBeenLoaded: groupHasBeenLoaded,
		error: groupError
	} = useSelector((state) => state.groupsSlice.group);
	const {
		isLoading: groupIsUpdating,
		hasBeenLoaded: successfullyUpdatedGroup
	} = useSelector((state) => state.groupsSlice.updateGroup);

	React.useEffect(() => {
		if (groupId) {
			dispatch(getGroup({ groupId }));
		}

		return () => {
			dispatch(resetGroup());
		};
	}, [groupId]);

	React.useEffect(() => {
		if (group?.id === parseInt(groupId)) {
			const initialGroupConfiguration = deepCopy(defaultGroupConfiguration);

			initialGroupConfiguration.buildType = groupBuildTypes[group.type];
			initialGroupConfiguration.groupLabel = group.groupLabel;
			initialGroupConfiguration.abbreviation = group.abbreviation;
			initialGroupConfiguration.groupItems = group.groupItems;

			setTemporaryGroupValues(group.groupItems);
			setGroupConfiguration(initialGroupConfiguration);
		}
	}, [groupId, group]);

	React.useEffect(() => {
		if (successfullyUpdatedGroup) {
			dispatch(resetUpdateGroup());
			navigate('/groups');
		}
	}, [successfullyUpdatedGroup]);

	const onCancel = () => {
		navigate('/groups');
	};

	const onSubmit = () => {
		const groupDefinition = {
			groupLabel: groupConfiguration.groupLabel,
			abbreviation: groupConfiguration.abbreviation,
			type: groupConfiguration.buildType?.id,
			userId: user.id,
			updatedOn: getCurrentTimestamp(),
			groupItems: groupConfiguration.groupItems
		};

		dispatch(
			updateGroup({
				groupId,
				groupDefinition
			})
		);
	};

	if (groupError) {
		return <PageError errorText={t('groups:groupWizard.editGroupError')} />;
	}

	if (groupIsLoading || !groupConfiguration) {
		return (
			<Stack direction='row' justifyContent='center' mt={2} pb={2}>
				<CircularProgress />
			</Stack>
		);
	}

	return (
		<GroupWizard
			groupConfiguration={groupConfiguration}
			setGroupConfiguration={setGroupConfiguration}
			onSubmit={onSubmit}
			onCancel={onCancel}
			temporaryGroupValues={temporaryGroupValues}
			setTemporaryGroupValues={setTemporaryGroupValues}
			disableTypeSelect={true}
			isLoading={groupIsUpdating}
		/>
	);
};

EditGroup.propTypes = {
	user: PropTypes.object,
	group: PropTypes.object,
	groupIsLoading: PropTypes.bool,
	groupHasBeenLoaded: PropTypes.bool,
	groupError: PropTypes.string,
	getGroup: PropTypes.func,
	t: PropTypes.func,
	updateGroup: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user')
	};
};

export default connect(mapStateToProps, null)(withTranslation()(EditGroup));
