import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getNotificationRuleReport,
	getNotificationRuleReportCompleted,
	getNotificationRuleReportFailed
} from './reportsSlice';

export const getNotificationsRuleReportEpic = (action$) =>
	action$.pipe(
		ofType(getNotificationRuleReport),
		mergeMap(async (action) => {
			await refreshToken();

			const { queryParameterString } = action.payload;

			const response = await axios.get(
				`${primaryRestGateway(true)}/reports/1815860b-108d-4167-9b61-a552f001d6c1`,
				{
					params: queryParameterString
				}
			);

			return response.data;
		}),
		switchMap((res) => [getNotificationRuleReportCompleted(res)]),
		catchError((error, source) =>
			merge(of(getNotificationRuleReportFailed(error.message)), source)
		)
	);

export default [getNotificationsRuleReportEpic];
