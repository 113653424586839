import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SortableTable from '../../SortableTable';
import GroupsTableRow from './Table/GroupsTableRow';
import PageError from '../../errors/PageError';
import ConfirmGroupDeleteModal from './ConfirmGroupDeleteModal';
import EnhancedTableToolbar from './Table/EnhancedTableToolbar';
import columnDefinitions from './Table/constants/columnDefinitions';
import searchableColumns from './Table/constants/searchableColumns';

import { 
  getGroups,
  resetGroups,
  deleteGroup,
  resetDeleteGroup,
 } from '../../../containers/groups/groupsSlice';

import Box from '@mui/material/Box';

const GroupManager = () => {
  const dispatch = useDispatch();

	const [groupMarkedForDeletion, setGroupMarkedForDeletion] = React.useState();
	const [searchTerm, setSearchTerm] = React.useState('');

  const {
    data: groups,
    isLoading: groupsAreLoading,
    hasBeenLoaded: groupsHaveBeenLoaded,
    error: groupsError
  } = useSelector(state => state.groupsSlice.groups);
  const {
    isLoading: isAddingGroup,
  } = useSelector(state => state.groupsSlice.addGroup);
  const {
    isLoading: isDeletingGroup,
    hasCompleted: successfullyDeletedGroup
  } = useSelector(state => state.groupsSlice.deleteGroup);

	React.useEffect(() => {	
    dispatch(getGroups());

    return () => {
      dispatch(resetGroups());
    }
	}, []);

  React.useEffect(() => {
    if (successfullyDeletedGroup) {
      dispatch(resetDeleteGroup());
      dispatch(getGroups());
    }
  }, [successfullyDeletedGroup])

	const onConfirmDelete = () => {
		dispatch(deleteGroup({
			groupId: groupMarkedForDeletion
		}));
		setGroupMarkedForDeletion();
	};

	if (groupsError) {
		return <PageError />;
	}

	return (
		<Box>
			<EnhancedTableToolbar
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
			/>
			<SortableTable
				data={groups}
				isLoading={isAddingGroup || isDeletingGroup || groupsAreLoading}
				hasBeenLoaded={groupsHaveBeenLoaded}
				initialSortColumn={'abbreviation'}
				columnDefinitions={columnDefinitions}
				TableRowComponent={GroupsTableRow}
				searchTerm={searchTerm}
				rowProps={{
					onMarkGroupForDeletion: setGroupMarkedForDeletion
				}}
				translationContext='groups'
				searchableColumns={searchableColumns}
			/>
			<ConfirmGroupDeleteModal
				isOpen={!!groupMarkedForDeletion}
				onConfirmDelete={onConfirmDelete}
				groupMarkedForDeletion={groupMarkedForDeletion}
				closeModal={() => setGroupMarkedForDeletion()}
			/>
		</Box>
	);
};

GroupManager.propTypes = {
	groups: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	groupsAreLoading: PropTypes.bool,
	groupsHaveBeenLoaded: PropTypes.bool,
	groupsError: PropTypes.string,
	isAddingGroup: PropTypes.bool,
	isDeletingGroup: PropTypes.bool,
	getGroups: PropTypes.func,
	deleteGroup: PropTypes.func
};

export default GroupManager;
