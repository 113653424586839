import React from 'react';
import PropTypes from 'prop-types';
import NameGroup from './stages/NameGroup';
import Submit from './stages/Submit';
import deepCopy from '../../../utilities/deepCopy';
import BuildStyleSelect from './stages/BuildStyleSelect';
import GroupItemsSelect from './stages/GroupItemsSelect';

import Stack from '@mui/material/Stack';

export const GroupWizard = ({
  groupConfiguration,
  setGroupConfiguration,
  onSubmit,
  onCancel,
  temporaryGroupValues,
  setTemporaryGroupValues,
  disableTypeSelect
}) => {

	const cloneConfiguration = () => {
		return deepCopy(groupConfiguration);
	};

	const isGroupItemSelectCompleted = () => {
		return groupConfiguration?.buildType && groupConfiguration?.groupItems.length > 0;
	};

	const setSelectedBuildType = (value) => {
		const updatedConfiguration = cloneConfiguration();

		updatedConfiguration.buildType = value || '';

		setGroupConfiguration(updatedConfiguration);
	};

	const setGroupItems = (value) => {
		const updatedConfiguration = cloneConfiguration();

		updatedConfiguration.groupItems = value;

		setGroupConfiguration(updatedConfiguration);
	};

	const setGroupLabel = (value) => {
		const updatedConfiguration = cloneConfiguration();

		updatedConfiguration.groupLabel = value;

		setGroupConfiguration(updatedConfiguration);
	};

	const setAbbreviation = (value) => {
		const updatedConfiguration = cloneConfiguration();

		updatedConfiguration.abbreviation = value;

		setGroupConfiguration(updatedConfiguration);
	};

	return (
		<Stack direction='column' spacing={2} alignItems='center' mt={2} mb={2}>
			<BuildStyleSelect
				isStageCompleted={!!groupConfiguration.buildType}
				selectedBuildType={groupConfiguration.buildType}
				setSelectedBuildType={setSelectedBuildType}
				isStageDisabled={disableTypeSelect}

			/>
			<GroupItemsSelect
				selectedBuildType={groupConfiguration.buildType}
				groupItems={groupConfiguration.groupItems}
				setGroupItems={setGroupItems}
				isStageCompleted={groupConfiguration.groupItems?.length > 0}
				isPreviousStageCompleted={!!groupConfiguration.buildType}
				temporaryGroupValues={temporaryGroupValues}
				setTemporaryGroupValues={setTemporaryGroupValues}
			/>
			<NameGroup
				groupLabel={groupConfiguration.groupLabel}
				setGroupLabel={setGroupLabel}
				abbreviation={groupConfiguration.abbreviation}
				setAbbreviation={setAbbreviation}
				isPreviousStageCompleted={groupConfiguration.groupItems.length > 0}
				isStageCompleted={groupConfiguration.groupLabel > 1}
			/>
			<Submit
				onSubmit={onSubmit}
				onCancel={onCancel}
				isPreviousStageCompleted={
					isGroupItemSelectCompleted() &&
					groupConfiguration.groupLabel.length > 0 &&
					groupConfiguration.abbreviation.length > 0
				}
				isStageCompleted={false}
			/>
		</Stack>
	);
};

GroupWizard.propTypes = {
	groupConfiguration: PropTypes.object,
	setGroupConfiguration: PropTypes.func,
	temporaryGroupValues: PropTypes.array,
	setTemporaryGroupValues: PropTypes.func,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	disableTypeSelect: PropTypes.bool
};

export default GroupWizard;
