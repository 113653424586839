import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import OutlinedButton from '../buttons/OutlinedButton';
import { modalActions } from '../../containers/Modal';
import { multiFactorAuthConstants } from '../../containers/MultiFactorAuth';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const _renderStatus = (preferredMFA) => {
	return preferredMFA === multiFactorAuthConstants.softwareMfa
		? 'account:multiFactorAuthentication.enabled'
		: 'account:multiFactorAuthentication.notConfigured';
};

export const MultiFactorAuthenticationSection = (props) => {
	const { t, userPreferredMFA, isMultiFactorAuthSetupModalOpen } = props;

	const navigate = useNavigate();

	const onOpenModal = (modalFunction) => {
		const { openModal } = props;

		navigate(`/account/${modalFunction}`);
		openModal();
	};

	return (
		<Stack>
			<Stack direction='row' alignItems='center'>
				<Typography variant='h5'>
					{t('multiFactorAuthentication.mfa')}
				</Typography>
			</Stack>
			<Stack direction='row' alignItems='center' px={2} mt={2}>
				<Box px={3}>
					{isMultiFactorAuthSetupModalOpen ? (
						<CircularProgress />
					) : (
						<>{t(_renderStatus(userPreferredMFA))}</>
					)}
				</Box>
				<Box px={3}>
					<OutlinedButton
						onClick={() =>
							onOpenModal(
								userPreferredMFA === multiFactorAuthConstants.softwareMfa
									? 'deactivate-mfa'
									: 'activate-mfa'
							)
						}
					>
						{userPreferredMFA === multiFactorAuthConstants.softwareMfa
							? t('common:buttons.disable')
							: t('common:buttons.setup')}
					</OutlinedButton>
				</Box>
			</Stack>
		</Stack>
	);
};

MultiFactorAuthenticationSection.propTypes = {
	openModal: PropTypes.func,
	userPreferredMFA: PropTypes.string,
	isMultiFactorAuthSetupModalOpen: PropTypes.bool,
	user: PropTypes.object,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.authReducer.get('user'),
		userPreferredMFA: state.multiFactorAuthReducer.get('preferredMFA'),
		isMultiFactorAuthSetupModalOpen: state.modalReducer.get(
			'isMultiFactorAuthSetupModalOpen'
		)
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		openModal: () => {
			dispatch(modalActions.toggleMultiFactorAuthSetupModal());
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['account', 'common'])(MultiFactorAuthenticationSection));
