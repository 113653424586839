import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import dayjs from 'dayjs';
import App from './app';
import { landingPageURI } from './utilities/apiEndpointUtility';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

const handleRouteToLandingPage = () => {
	window.location.replace(landingPageURI());
};

const setPool = () => {
	const searchParams = new URLSearchParams(window.location.search);

	if (searchParams) {
		const locationParams = {
			CPI: searchParams.get('CPI'),
			ACID: searchParams.get('ACID'),
			ORG: searchParams.get('ORG'),
			REG: searchParams.get('REG'),
			UNAME: searchParams.get('UNAME'),
			UPID: searchParams.get('UPID')
		};

		let parsedCPI;

		// LANDING PAGE SUPPORT - TEMPORARY
		if (locationParams.CPI) {
			const decodedCPI = decodeURIComponent(locationParams.CPI);
			parsedCPI = JSON.parse(decodedCPI);
		}

		const ACID = parsedCPI ? parsedCPI.ACID : locationParams.ACID;
		const ORG = parsedCPI ? parsedCPI.ORG : locationParams.ORG;
		const REG = parsedCPI ? parsedCPI.REG : locationParams.REG;
		const UNAME = parsedCPI ? parsedCPI.UNAME : locationParams.UNAME;
		const UPID = parsedCPI ? parsedCPI.UPID : locationParams.UPID;

		if (ACID && ORG && REG && UNAME && UPID) {
			// eslint-disable-next-line
			localStorage.setItem(
				'CPI',
				JSON.stringify({
					ACID,
					ORG,
					REG,
					UNAME,
					UPID
				})
			);
			window.location.search = '';
			return true;
		} else if (ACID && ORG && REG && UPID) {
			localStorage.setItem(
				'CPI',
				JSON.stringify({
					ACID,
					ORG,
					REG,
					UPID
				})
			);
		}
	}

	// eslint-disable-next-line
	const CPI = localStorage.getItem('CPI');
	if (CPI) {
		return true;
	}
};

if (setPool()) {
	// eslint-disable-next-line
	const { ACID, REG, UPID } = JSON.parse(localStorage.getItem('CPI'));
	Amplify.configure({
		Auth: {
			mandatorySignIn: true,
			region: REG,
			userPoolId: UPID,
			userPoolWebClientId: ACID
		}
	});

	const container = document.getElementById('root');
	const root = createRoot(container);

	root.render(<App />);
} else {
	if (process.env.REACT_APP_LOCAL !== 'true') {
		handleRouteToLandingPage();
	} else {
		console.log('Please set your Pool');
	}
}
