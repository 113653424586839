import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import getSubmitErrors from '../../shared/validators/getSubmitErrors';
import ContainedLoadingButton from '../../../buttons/ContainedLoadingButton';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const UpdateRow = ({
	t,
	ruleConfiguration,
	onClickSubmit,
	isUpdatingRule,
	updateError,
	setSubmitErrors
}) => {
	const navigate = useNavigate();

	const onUpdateClicked = () => {
		const submitErrors = getSubmitErrors(ruleConfiguration, t);

		if (submitErrors.length > 0) {
			setSubmitErrors(submitErrors);
		} else {
			setSubmitErrors();
			onClickSubmit();
		}
	};

	const onClickCancel = () => {
		navigate('/rules');
	};

	return (
		<Stack
			direction='row'
			justifyContent='flex-end'
			alignItems='center'
			spacing={1}
			p={1}
			sx={{ maxWidth: '900px', width: '100%',	alignSelf: 'center' }}
			component={Paper}
		>
			<Box sx={{ flex: 1 }}>
				{updateError ? (
					<Stack>
						<Typography color='error' sx={{ fontWeight: 600 }}>
							{t('rules:submitRow.failureUpdateMessage')}
						</Typography>
						<Typography variant='caption'>{updateError}</Typography>
					</Stack>
				) : null}
			</Box>
			<Button variant='outlined' onClick={onClickCancel}>
				{t('buttons.cancel')}
			</Button>
			<Box sx={{ width: 150 }}>
				<ContainedLoadingButton
					loading={isUpdatingRule}
					onClick={onUpdateClicked}
				>
					{t('common:buttons.update')}
				</ContainedLoadingButton>
			</Box>
		</Stack>
	);
};

UpdateRow.propTypes = {
	t: PropTypes.func,
	ruleConfiguration: PropTypes.object,
	onClickSubmit: PropTypes.func,
	isUpdatingRule: PropTypes.bool,
	updateError: PropTypes.string,
	submitErrors: PropTypes.array,
	setSubmitErrors: PropTypes.func
};

export default withTranslation(['common', 'rules'])(UpdateRow);
