import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import SavedRulesContext from '../savedRulesProvider/SavedRulesContext';
import RuleRow from './components/RuleRow';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import ConfirmDeleteModal from '../../modals/ConfirmDeleteModal';
import ErrorIndicator from '../../errors/ErrorIndicator';

import {
	updateActiveStatus,
	resetUpdateActiveStatus,
	resetDeleteUserRule
} from '../../../containers/Rules/userRuleSlice';

import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const RuleList = ({ t }) => {
	const dispatch = useDispatch();
	const { currentPage, getUserRules, onRunUntrackedUserRule } =
		React.useContext(SavedRulesContext);

	const navigate = useNavigate();

	const [markedForDeletionId, setMarkedForDeletionId] = React.useState();

	const {
		data: savedRules,
		isLoading: savedRulesAreLoading,
		hasBeenLoaded: savedRulesHaveBeenLoaded,
		error: savedRulesError
	} = useSelector((state) => state.userRulesSlice.userRules);
	const {
		userRuleId: updatingRuleId,
		hasCompleted: hasUpdatedRuleActiveStatus,
		error: updateActiveStatusError
	} = useSelector((state) => state.userRuleSlice.updateActiveStatus);
	const { hasCompleted: successfullyDeletedUserRule } = useSelector(
		(state) => state.userRuleSlice.deleteUserRule
	);
	const { ruleRunToken, ruleRunTokenError } = useSelector(
		(state) => state.rulesRunnerTokenSlice
	);
	const { runningUserRuleId, runningUserRuleCompleted, runningUserRuleError } =
		useSelector((state) => state.rulesRunnerUntrackedSlice);

	React.useEffect(() => {
		if (hasUpdatedRuleActiveStatus) {
			getUserRules(currentPage);
			dispatch(resetUpdateActiveStatus());
		}
	}, [hasUpdatedRuleActiveStatus]);

	React.useEffect(() => {
		if (successfullyDeletedUserRule) {
			getUserRules(currentPage);
			dispatch(resetDeleteUserRule());
		}
	}, [successfullyDeletedUserRule]);

	const onEditRule = (ruleId) => {
		navigate(`/rules/edit/${ruleId}`);
	};

	const onUpdateRuleActiveStatus = (userRuleId) => {
		dispatch(
			updateActiveStatus({
				userRuleId
			})
		);
	};

	return (
		<Stack direction='column' px={2}>
			<ErrorIndicator
				error={ruleRunTokenError ? t('rules:connectionFailure') : null}
			/>
			<ErrorIndicator error={updateActiveStatusError} />
			{
				<ErrorIndicator
					error={runningUserRuleError ? t('rules:failedToRunRule') : null}
				/>
			}
			{savedRulesAreLoading && !savedRulesHaveBeenLoaded ? (
				<Stack direction='row' justifyContent='center'>
					<CircularProgress />
				</Stack>
			) : null}
			<Stack direction='row' my={0.5}>
				<LinearProgress
					sx={{
						width: '100%',
						visibility:
							savedRulesAreLoading && savedRulesHaveBeenLoaded
								? 'block'
								: 'hidden'
					}}
				/>
			</Stack>
			<Stack direction='column' spacing={2}>
				{savedRulesHaveBeenLoaded && !savedRulesError
					? savedRules?.map((rule) => (
							<RuleRow
								key={rule.userRuleId}
								rule={rule}
								isMarkedForDeletion={markedForDeletionId === rule.userRuleId}
								isUpdatingRule={updatingRuleId === rule.userRuleId}
								onEditRule={onEditRule}
								onUpdateRuleActiveStatus={onUpdateRuleActiveStatus}
								runningUserRuleId={runningUserRuleId}
								runningUserRuleCompleted={runningUserRuleCompleted}
								runningUserRuleError={runningUserRuleError}
								onRunUntrackedUserRule={
									ruleRunToken ? onRunUntrackedUserRule : undefined
								}
								onDeleteRule={setMarkedForDeletionId}
							/>
						))
					: null}
			</Stack>
			{savedRulesHaveBeenLoaded &&
			!savedRulesError &&
			isDataEmpty(savedRules) ? (
				<Stack direction='row' justifyContent='center'>
					<Typography variant='overline'>
						{t('notifications:noResults')}
					</Typography>
				</Stack>
			) : null}
			<ConfirmDeleteModal
				isOpen={!!markedForDeletionId}
				userRuleId={markedForDeletionId}
				closeModal={() => setMarkedForDeletionId()}
			>
				<Typography paragraph>{t('rules:deleteRule')}</Typography>
				<Typography paragraph>{t('rules:continue')}</Typography>
			</ConfirmDeleteModal>
		</Stack>
	);
};

RuleList.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['rules', 'common'])(RuleList);
