import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const MerchantTooltip = (props) => {
	const { value = [] } = props;

	const validatedEntries =
		value?.filter((entry) => {
			return !(!entry || !entry.midLabel);
		}) || [];

	return (
		<React.Fragment>
			{validatedEntries.map((entry) => {
				return (
					<Typography
						key={entry.midId}
						variant='caption'
						paragraph={true}
						m={0}
					>{`${entry?.midLabel} - ${entry?.paymentEnablerAcquiringBankLabel} - ${entry?.fma}`}</Typography>
				);
			})}
		</React.Fragment>
	);
};

MerchantTooltip.propTypes = {
	value: PropTypes.array
};

export default MerchantTooltip;
