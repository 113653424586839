import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { modalActions } from '../../../containers/Modal';
import { usersActions } from '../../../containers/Users';
import BoldLargeHeader from '../../headers/BoldLargeHeader';
import ContainedButton from '../../buttons/ContainedButton';
import CompletionPanel from './CompletionPanel';
import GenericModalPaperWrapper from '../GenericModalPaperWrapper';
import TextButton from '../../buttons/TextButton';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const InviteUserModal = (props) => {
	const {
		closeModal,
		hasBeenInvited,
		isInviting,
		invitationError,
		inviteUser,
		isInviteUserModalOpen,
		resetInvitation,
		t
	} = props;

	const [emailInput, setEmailInput] = React.useState('');

	const validEmailCheck = (email) => {
		let isValid = true;
		const validEmail = /^\S+@\S+\.\S+$/;
		!email.match(validEmail) ? (isValid = false) : (isValid = true);
		return isValid;
	};

	const handleClose = () => {
		setEmailInput('');
		closeModal();
	};

	const handleReset = () => {
		resetInvitation();
		handleClose();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		inviteUser({ email: emailInput });
	};

	return (
		<GenericModalPaperWrapper
			open={isInviteUserModalOpen}
			onClose={handleClose}
		>
			{hasBeenInvited && !isInviting ? (
				<CompletionPanel onClose={handleReset} />
			) : null}
			{isInviting ? (
				<Stack alignItems='center' justifyContent='center' sx={{ height: 300 }}>
					<CircularProgress />
				</Stack>
			) : null}
			{!hasBeenInvited && !isInviting ? (
				<Stack spacing={3} p={3} m={2}>
					<BoldLargeHeader>{t('modals:inviteUser.title')}</BoldLargeHeader>
					{invitationError ? (
						<Typography color='error'>
							{t('common:error.somethingWentWrong')}
						</Typography>
					) : null}
					<form>
						<TextField
							error={!validEmailCheck(emailInput) && emailInput !== ''}
							fullWidth
							onChange={(e) => setEmailInput(e.target.value)}
							value={emailInput}
							label={t('modals:inviteUser.enterEmail')}
						/>
						<Stack direction='row' justifyContent='flex-end' m={1}>
							<TextButton onClick={handleClose}>
								{t('common:buttons.cancel')}
							</TextButton>
							<ContainedButton
								type='submit'
								onClick={handleSubmit}
								disabled={!validEmailCheck(emailInput)}
							>
								{t('modals:inviteUser.buttons.sendInvite')}
							</ContainedButton>
						</Stack>
					</form>
				</Stack>
			) : null}
		</GenericModalPaperWrapper>
	);
};

InviteUserModal.propTypes = {
	closeModal: PropTypes.func,
	hasBeenInvited: PropTypes.bool,
	isInviting: PropTypes.bool,
	invitationError: PropTypes.string,
	inviteUser: PropTypes.func,
	isInviteUserModalOpen: PropTypes.bool,
	resetInvitation: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		hasBeenInvited: state.usersReducer.get('hasBeenInvited'),
		isInviting: state.usersReducer.get('isInviting'),
		isInviteUserModalOpen: state.modalReducer.get('isInviteUserModalOpen'),
		invitationError: state.usersReducer.get('invitationError')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeModal: () => {
			dispatch(modalActions.toggleInviteUserModal());
		},
		inviteUser: (payload) => {
			dispatch(usersActions.inviteUser(payload));
		},
		resetInvitation: (payload) => {
			dispatch(usersActions.resetInvitation(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['common', 'modals'])(InviteUserModal));
