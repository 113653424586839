import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	notificationRuleReport: {
		data: [],
		currentPage: 1,
		availablePageCount: 0,
		hasBeenLoaded: false,
		isLoading: false,
		error: undefined
	}
};

export const reportsSlice = createSlice({
	name: 'savedRules',
	initialState,
	reducers: {
		getNotificationRuleReport: (state) => {
			state.notificationRuleReport.isLoading = true;
			state.notificationRuleReport.error = undefined;
		},
		getNotificationRuleReportCompleted: (state, action) => {
			state.notificationRuleReport.isLoading = false;
			state.notificationRuleReport.hasBeenLoaded = true;
			state.notificationRuleReport.data = action.payload.rows;
			state.notificationRuleReport.availablePageCount =
				action.payload.availablePageCount;
			state.notificationRuleReport.currentPage = action.payload.page;
		},
		getNotificationRuleReportFailed: (state, action) => {
			state.notificationRuleReport.isLoading = false;
			state.notificationRuleReport.error = action.payload;
		},
		resetGetNotificationRuleReport: (state) => {
			state.notificationRuleReport = initialState.notificationRuleReport;
		}
	}
});

export const {
	getNotificationRuleReport,
	getNotificationRuleReportCompleted,
	getNotificationRuleReportFailed,
	resetGetNotificationRuleReport
} = reportsSlice.actions;

export default reportsSlice.reducer;
