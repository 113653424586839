import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	validateInvitationId: {
		result: undefined,
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	submitRegistration: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	},
	cancelInvitation: {
		isLoading: false,
		hasCompleted: false,
		error: undefined
	}
};

export const registrationSlice = createSlice({
	name: 'registrationNew',
	initialState,
	reducers: {
		validateInvitationId: (state) => {
			state.validateInvitationId.isLoading = true;
			state.validateInvitationId.error = undefined;
		},
		validateInvitationIdCompleted: (state, action) => {
			state.validateInvitationId.isLoading = false;
			state.validateInvitationId.hasCompleted = true;
			state.validateInvitationId.result = action.payload;
		},
		validateInvitationIdFailed: (state, action) => {
			state.validateInvitationId.isLoading = false;
			state.validateInvitationId.error = action.payload;
		},
		submitRegistration: (state) => {
			state.submitRegistration.isLoading = true;
			state.submitRegistration.error = undefined;
		},
		submitRegistrationCompleted: (state) => {
			state.submitRegistration.isLoading = false;
			state.submitRegistration.hasCompleted = true;
		},
		submitRegistrationFailed: (state, action) => {
			state.submitRegistration.isLoading = false;
			state.submitRegistration.error = action.payload;
		},
		cancelInvitation: (state) => {
			state.cancelInvitation.isLoading = true;
			state.cancelInvitation.error = undefined;
		},
		cancelInvitationCompleted: (state) => {
			state.cancelInvitation.isLoading = false;
			state.cancelInvitation.hasCompleted = true;
		},
		cancelInvitationFailed: (state, action) => {
			state.cancelInvitation.isLoading = false;
			state.cancelInvitation.error = action.payload;
		}
	}
});

export const {
	validateInvitationId,
	validateInvitationIdCompleted,
	validateInvitationIdFailed,
	submitRegistration,
	submitRegistrationCompleted,
	submitRegistrationFailed,
	cancelInvitation,
	cancelInvitationCompleted,
	cancelInvitationFailed
} = registrationSlice.actions;

export default registrationSlice.reducer;
