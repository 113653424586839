import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	addNotificationTimelineEvent,
	addNotificationTimelineEventCompleted,
	addNotificationTimelineEventFailed,
	getNotification,
	getNotificationCompleted,
	getNotificationFailed,
	getNotifications,
	getNotificationsCompleted,
	getNotificationsFailed,
	getNotificationTimeline,
	getNotificationTimelineCompleted,
	getNotificationTimelineFailed,
	updateNotification,
	updateNotificationCompleted,
	updateNotificationFailed
} from './notificationsSlice';

export const getNotificationsEpic = (action$) =>
	action$.pipe(
		ofType(getNotifications),
		mergeMap(async (action) => {
			await refreshToken();

			const payload = action.payload;

			const response = await axios.get(
				`${primaryRestGateway(true)}/notifications`,
				{
					params: payload
				}
			);

			return response.data;
		}),
		switchMap((response) => [getNotificationsCompleted(response)]),
		catchError((error, source) =>
			merge(of(getNotificationsFailed(error.message)), source)
		)
	);

export const getNotificationEpic = (action$) =>
	action$.pipe(
		ofType(getNotification),
		mergeMap(async (action) => {
			await refreshToken();
			const { notificationId } = action.payload;
			const response = await axios.get(
				`${primaryRestGateway(true)}/notifications/${notificationId}`
			);
			return response.data;
		}),
		switchMap((res) => [getNotificationCompleted(res)]),
		catchError((error, source) =>
			merge(of(getNotificationFailed(error.message)), source)
		)
	);

export const getNotificationTimelineEpic = (action$) =>
	action$.pipe(
		ofType(getNotificationTimeline),
		mergeMap(async (action) => {
			await refreshToken();
			const { notificationId } = action.payload;
			const response = await axios.get(
				`${primaryRestGateway(true)}/notifications/${notificationId}/timeline`
			);

			return response.data;
		}),
		switchMap((res) => [getNotificationTimelineCompleted(res)]),
		catchError((error, source) =>
			merge(of(getNotificationTimelineFailed(error.message)), source)
		)
	);

export const updateNotificationEpic = (action$) =>
	action$.pipe(
		ofType(updateNotification),
		mergeMap(async (action) => {
			await refreshToken();
			const { notificationId, notification } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway(true)}/notifications/${notificationId}`,
				notification
			);

			return response.data;
		}),
		switchMap((response) => [updateNotificationCompleted(response)]),
		catchError((error, source) =>
			merge(of(updateNotificationFailed(error.message)), source)
		)
	);

export const addNotificationTimelineEventEpic = (action$) =>
	action$.pipe(
		ofType(addNotificationTimelineEvent),
		mergeMap(async (action) => {
			await refreshToken();

			const { notificationId, notificationDetail } = action.payload;
			const response = await axios.post(
				`${primaryRestGateway(true)}/notifications/${notificationId}/timeline`,
				{ notificationId, notificationDetail }
			);

			return response.data;
		}),
		switchMap((response) => [addNotificationTimelineEventCompleted(response)]),
		catchError((error, source) =>
			merge(of(addNotificationTimelineEventFailed(error.message)), source)
		)
	);
export default [
	getNotificationsEpic,
	getNotificationEpic,
	getNotificationTimelineEpic,
	updateNotificationEpic,
	addNotificationTimelineEventEpic
];
