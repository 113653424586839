import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import MultipSelectTooltip from '../../shared/MultiSelectChipTooltip';
import SubtextError from '../../errors/SubtextError';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import makeArray from '../../../utilities/makeArray';

import {
	getProcessors,
	resetProcessors
} from '../../../containers/processors/processorsSlice';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

const ProcessorSelect = ({
	onChange,
	value,
	t,
	isMultiSelect,
	listItemsIndividually
}) => {
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);

	const {
		data: processors,
		isLoading: processorsAreLoading,
		hasBeenLoaded: processorsHaveBeenLoaded,
		error: processorsError
	} = useSelector((state) => state.processorsSlice.processors);

	React.useEffect(() => {
		dispatch(getProcessors());

		return () => {
			dispatch(resetProcessors());
		};
	}, []);

	React.useEffect(() => {
		if (open && processorsHaveBeenLoaded) {
			setOptions(processors ?? []);
		}
	}, [open, options, processors]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<FormControl sx={{ flex: 1, display: 'flex' }}>
			{processorsError ? (
				<Box pl={1}>
					<SubtextError errorText={t('inputs:unableToLoadProcessors')} />
				</Box>
			) : null}
			<Autocomplete
				multiple={isMultiSelect}
				filterSelectedOptions
				freeSolo
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={processorsAreLoading}
				disabled={!processorsHaveBeenLoaded || processorsAreLoading}
				isOptionEqualToValue={(option, item) => {
					return isDataEmpty(item)
						? ''
						: option?.paymentEnablerAcquiringBankId ===
								item?.paymentEnablerAcquiringBankId;
				}}
				getOptionLabel={(option) => {
					return isDataEmpty(option)
						? ''
						: (option?.description ?? option.label);
				}}
				renderTags={
					!listItemsIndividually
						? (value) => (
								<Tooltip
									title={
										<MultipSelectTooltip
											value={value}
											idKey='paymentEnablerAcquiringBankId'
											labelKey='label'
										/>
									}
								>
									<Chip variant='outlined' label={`${value.length} selected`} />
								</Tooltip>
							)
						: undefined
				}
				value={value || null}
				onChange={(event, newValue) => {
					if (isMultiSelect) {
						onChange(makeArray(newValue));
					} else {
						onChange(newValue);
					}
				}}
				inputValue={inputValue}
				onInputChange={(event, value) => {
					setInputValue(value);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						sx={{ flex: 1 }}
						label={t('inputs:selectProcessor')}
						helperText={t('inputs:ctrlHelperText')}
						variant='outlined'
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{processorsAreLoading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

ProcessorSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.array
	]),
	t: PropTypes.func,
	temporaryValue: PropTypes.string,
	setTemporaryValue: PropTypes.func,
	isStageDisabled: PropTypes.bool
};

export default withTranslation(['rules', 'groups'])(ProcessorSelect);
