import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { supportActions } from '../../containers/Support';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import BorderedPaper from '../branded/BorderedPaper';
import OutlinedButton from '../buttons/OutlinedButton';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const SuccessPanel = (props) => {
	const {
		t,
		hasBeenSuccessfullySubmitted,
		error,
		clearSupportForm
	} = props;

	const navigate = useNavigate();

	React.useEffect(() => {
		if (!hasBeenSuccessfullySubmitted && !error) {
			clearSupportForm();
			navigate('/support');
		}
	}, [hasBeenSuccessfullySubmitted, error]);

	const onBackToDashboard = () => {
		clearSupportForm();
		navigate('/dashboard');
	};

	return (
		<Stack direction='row' justifyContent='center'>
			<BorderedPaper sx={{ my: 1.5 }}>
				<Stack alignItems='center' p={4} spacing={2}>
					<BoldLargeHeader>
						{t('successPanel.success')}
					</BoldLargeHeader>
					<Typography paragraph>
						{t('successPanel.thanksForSubmitting')}
					</Typography>
					<OutlinedButton onClick={onBackToDashboard}>
						{t('successPanel.backToDashboard')}
					</OutlinedButton>
				</Stack>
			</BorderedPaper>
		</Stack>
	);
};

SuccessPanel.propTypes = {
	hasBeenSuccessfullySubmitted: PropTypes.bool,
	error: PropTypes.string,
	clearSupportForm: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isSubmitting: state.supportReducer.getIn(['supportForm', 'isSubmitting']),
		hasBeenSuccessfullySubmitted: state.supportReducer.getIn([
			'supportForm',
			'hasBeenSuccessfullySubmitted'
		]),
		error: state.supportReducer.getIn(['supportForm', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearSupportForm: (payload) => {
			dispatch(supportActions.clearSupportForm(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('support')(SuccessPanel));
