import React from 'react';
import { Navigate, Outlet } from 'react-router';
import PropTypes from 'prop-types';
import Layout from '../components/LayoutWrapper/Layout/Layout';

const PrivateLayoutRoute = (props) => {
	const { isAuthed, ...otherProps } = props;

	if (!isAuthed) {
		return <Navigate
			to={{
				pathname: '/login',
				state: { from: location }
			}}
		/>;
	}

	return (
		<Layout>
			<Outlet {...otherProps} />
		</Layout>
	);
};

PrivateLayoutRoute.propTypes = {
	children: PropTypes.object,
	element: PropTypes.node,
	isAuthed: PropTypes.bool,
	path: PropTypes.string,
	Render: PropTypes.func
};

export default PrivateLayoutRoute;
