import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import registrationStages from '../constants/registrationStages';
import AvatarDisplay from '../AvatarDisplay';
import fileUploadHandler from '../../../utilities/fileUploadHandler';
import fileUploadErrors from '../../../constants/fileUploadErrors';
import BoldLargeHeader from '../../headers/BoldLargeHeader';
import ContainedButton from '../../buttons/ContainedButton';
import OverlineTextError from '../../errors/OverlineTextError';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const StyledFileSizeText = styled(Typography)(({ theme }) => ({
	fontSize: '.6rem',
	fontWeight: 700,
	whiteSpace: 'nowrap',
	color: theme.palette.common.black,
	alignSelf: 'center'
}));

export const AvatarUpload = (props) => {
	const { newUserConfiguration, onConfigurationChange, setCurrentStage, t } = props;

	const [isUploadingAvatar, setIsUploadingAvatar] = React.useState(false);
	const [uploadedAvatar, setUploadedAvatar] = React.useState(
		newUserConfiguration.avatar
	);
	const [fileUploadError, setFileUploadError] = React.useState();

	const onUploadFinished = (data) => {
		setUploadedAvatar(data);
		setIsUploadingAvatar(false);
	};

	const onUpload = async (event) => {
		try {
			fileUploadHandler(event.target.files[0], onUploadFinished, 1024);
		} catch (error) {
			if (error === fileUploadErrors.fileSizeLimit) {
				setFileUploadError(t('registrationForm.exceedMaxFileSize'));
				setIsUploadingAvatar(false);
			} else {
				setFileUploadError(error);
			}
		}
	};

	const onClickSave = () => {
		onConfigurationChange(uploadedAvatar, 'avatar');
		setCurrentStage(registrationStages.slyceInformation);
	};

	const onClickRemovePhoto = () => {
		setUploadedAvatar(undefined);
		onConfigurationChange(undefined, 'avatar');
		setFileUploadError();
	};

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' alignItems='center' mr={3} mb={2}>
				<Stack>
					<IconButton
						onClick={() => setCurrentStage(registrationStages.slyceInformation)}
					>
						<KeyboardArrowLeftIcon sx={{ fontSize: '2rem' }} color='primary' />
					</IconButton>
				</Stack>
				<Stack>
					<BoldLargeHeader py={2} px={4}>
						{t('registrationForm.changeProfilePicture')}
					</BoldLargeHeader>
				</Stack>
			</Stack>
			<Stack
				direction='column'
				alignItems='center'
				px={4}
				mx={2}
				spacing={2}
				mb={2}
			>
				{isUploadingAvatar ? (
					<CircularProgress
						size={125}
						thickness={0.75}
						sx={{ position: 'absolute', margin: '15px 0 0 0', zIndex: 20 }}
					/>
				) : null}
				<AvatarDisplay size={125} uploadedAvatar={uploadedAvatar} />
				{fileUploadError ? (
					<OverlineTextError>{fileUploadError}</OverlineTextError>
				) : null}
				<Button
					sx={{ textDecoration: 'underline', color: 'primary.alt' }}
					variant='text'
					component='label'
					onClick={() => {
						setIsUploadingAvatar(true);
						setFileUploadError();
					}}
				>
					{t('registrationForm.uploadPhoto')}
					<input
						hidden
						type='file'
						accept='image/png, image/jpeg, image/tif'
						onChange={onUpload}
					/>
				</Button>
				<Typography
					className={{ cursor: 'pointer', color: 'primary.alt' }}
					onClick={onClickRemovePhoto}
				>
					{t('registrationForm.removePhoto')}
				</Typography>
				<Stack direction='row' justifyContent='center' my={2}>
					<ContainedButton onClick={() => onClickSave()}>
						{t('registrationForm.save')}
					</ContainedButton>
				</Stack>
				<StyledFileSizeText>
					{t('registrationForm.maxFileSizeAndType')}
				</StyledFileSizeText>
			</Stack>
		</Box>
	);
};

AvatarUpload.propTypes = {
	newUserConfiguration: PropTypes.object,
	onConfigurationChange: PropTypes.func,
	setCurrentStage: PropTypes.func,
	t: PropTypes.func
};

export default withTranslation('registration')(AvatarUpload);
