import React from 'react';
import { Navigate, Outlet } from 'react-router';
import PropTypes from 'prop-types';

const PrivateRoute = (props) => {
	const { isAuthed, ...otherProps } = props;

	if (!isAuthed) {
		return <Navigate
			to={{
				pathname: '/login',
				state: { from: location }
			}}
		/>;
	}

	return (
		<Outlet {...otherProps} />
	);
};

PrivateRoute.propTypes = {
	children: PropTypes.object,
	element: PropTypes.node,
	isAuthed: PropTypes.bool,
	path: PropTypes.string,
	Render: PropTypes.func
};

export default PrivateRoute;
