import * as actionTypes from './actionTypes';

export const createRule = (payload) => ({
	type: actionTypes.CREATE_RULE,
	payload
});

export const createRuleCompleted = (payload) => ({
	type: actionTypes.CREATE_RULE_COMPLETED,
	payload
});

export const createRuleFailed = (payload) => ({
	type: actionTypes.CREATE_RULE_FAILED,
	payload
});

export const updateRule = (payload) => ({
	type: actionTypes.UPDATE_RULE,
	payload
});

export const updateRuleCompleted = (payload) => ({
	type: actionTypes.UPDATE_RULE_COMPLETED,
	payload
});

export const updateRuleFailed = (payload) => ({
	type: actionTypes.UPDATE_RULE_FAILED,
	payload
});

export const resetRuleCreate = (payload) => ({
	type: actionTypes.RESET_CREATE_RULE,
	payload
});

export const getActiveRulesByGroup = (payload) => ({
	type: actionTypes.GET_ACTIVE_RULES_BY_GROUP,
	payload
});

export const getActiveRulesByGroupCompleted = (payload) => ({
	type: actionTypes.GET_ACTIVE_RULES_BY_GROUP_COMPLETED,
	payload
});

export const getActiveRulesByGroupFailed = (payload) => ({
	type: actionTypes.GET_ACTIVE_RULES_BY_GROUP_FAILED,
	payload
});

export const deleteActiveRule = (payload) => ({
	type: actionTypes.DELETE_ACTIVE_RULE,
	payload
});

export const deleteActiveRuleCompleted = (payload) => ({
	type: actionTypes.DELETE_ACTIVE_RULE_COMPLETED,
	payload
});

export const deleteActiveRuleFailed = (payload) => ({
	type: actionTypes.DELETE_ACTIVE_RULE_FAILED,
	payload
});
