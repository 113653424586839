import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getMerchantCategoryCodes,
	getMerchantCategoryCodesCompleted,
	getMerchantCategoryCodesFailed
} from './merchantCategoryCodesSlice';

export const getMerchantCategoryCodesEpic = (action$) =>
	action$.pipe(
		ofType(getMerchantCategoryCodes),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway(true)}/datasources/merchant-category-codes`
			);

			return response.data;
		}),
		switchMap((res) => [getMerchantCategoryCodesCompleted(res)]),
		catchError((error, source) =>
			merge(of(getMerchantCategoryCodesFailed(error.message)), source)
		)
	);

export default [
	getMerchantCategoryCodesEpic
];
