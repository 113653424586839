import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { withTranslation } from 'react-i18next';
import Restricted from '../../../permissionProvider/Restricted';
import My360Header from '../../../components/my360/Header';
import RestrictedFallback from '../../../components/shared/RestrictedFallback';
import RulesListFilters from '../../../components/rules/RuleListFilters';
import RuleList from '../../../components/rules/RuleList';
import SavedRulesProvider from '../../../components/rules/savedRulesProvider/SavedRulesProvider';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const RuleListPage = ({ t }) => {
	const navigate = useNavigate();

	const onClickBuildRule = () => {
		navigate('/rules/build');
	};

	return (
		<Stack p={1}>
			<Restricted
				to='rules.ruleManagement.view'
				Fallback={<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />}
			>
				<My360Header label={t('rules:viewExistingRules')}>
					<Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }} pr={2}>
						<Button variant='contained' onClick={onClickBuildRule}>
							{t('rules:buildRule')}
						</Button>
					</Box>
				</My360Header>
				<SavedRulesProvider>
					<React.Fragment>
						<RulesListFilters />
						<RuleList />
					</React.Fragment>
				</SavedRulesProvider>
			</Restricted>
		</Stack>
	);
};

RuleListPage.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['rules', 'common'])(RuleListPage);
