import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SavedRulesContext from '../savedRulesProvider/SavedRulesContext';
import RuleCategorySelect from '../RuleCategorySelect';
import ApplyToSelect from '../ApplyToSelect';
import LevelSelect from '../LevelSelect';
import OutlinedLoadingButton from '../../buttons/OutlinedLoadingButton';

import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

export const NotificationListFilters = ({ t }) => {
	const {
		currentPage,
		onChangePage,
		selectedRuleCategory,
		setSelectedRuleCategory,
		selectedLevel,
		setSelectedLevel,
		selectedApplyTo,
		setSelectedApplyTo,
		getUserRules
	} = React.useContext(SavedRulesContext);

	const { isLoading, availablePageCount } = useSelector(
		(state) => state.userRulesSlice.userRules
	);

	const handlePageChange = (event, value) => {
		onChangePage(value);
	};

	const onClickApply = () => {
		getUserRules(1);
	};

	return (
		<Stack spacing={2} m={2}>
			<Grid
				component={Paper}
				elevation={1}
				container
				alignItems='center'
				p={1}
				spacing={1}
			>
				<Grid item xs={12} sm={12} md={4} lg={3}>
					<RuleCategorySelect
						selectedRuleCategory={selectedRuleCategory}
						onChangeSelection={setSelectedRuleCategory}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={3}>
					<LevelSelect
						selectedLevel={selectedLevel}
						onChangeSelection={setSelectedLevel}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={3}>
					<ApplyToSelect
						selectedApplyTo={selectedApplyTo}
						onChangeSelection={setSelectedApplyTo}
					/>
				</Grid>
				<Grid
					item
					xs={0}
					sm={0}
					md={9}
					lg={1}
					sx={{ display: { xs: 'none', md: 'block', lg: 'block' } }}
				/>
				<Grid item xs={12} sm={12} md={3} lg={2}>
					<OutlinedLoadingButton onClick={onClickApply} loading={isLoading}>
						{t('common:buttons.submit')}
					</OutlinedLoadingButton>
				</Grid>
			</Grid>
			<Stack direction='row' justifyContent='flex-end'>
				<Pagination
					count={availablePageCount}
					page={currentPage}
					onChange={handlePageChange}
					color='primary'
				/>
			</Stack>
		</Stack>
	);
};

NotificationListFilters.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['notifications', 'common'])(
	NotificationListFilters
);
