import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import {
	primaryRestGateway
} from '../../utilities/apiEndpointUtility';

import Logger from '../../utilities/logger';

import {
	validateInvitationId,
	validateInvitationIdCompleted,
	validateInvitationIdFailed,
	submitRegistration,
	submitRegistrationCompleted,
	submitRegistrationFailed,
	cancelInvitation,
	cancelInvitationCompleted,
	cancelInvitationFailed
} from './registrationSlice';

export const validateInvitationIdEpic = (action$) =>
	action$.pipe(
		ofType(validateInvitationId),
		mergeMap(async (action) => {
			const { clientId, invitationId } = action.payload;
			const params = { iId: invitationId, cId: clientId };
			const response = await axios.get(
				`${primaryRestGateway(true)}/registration/validate`,
				{ params }
			);

			if (response.data === false) {
				throw new Error('Invitation ID Could Not Be Validated');
			}

			return invitationId;
		}),
		switchMap((res) => [validateInvitationIdCompleted(res)]),
		catchError((error, source) =>
			merge(of(validateInvitationIdFailed(error.message)), source)
		)
	);

export const submitRegistrationEpic = (action$) =>
	action$.pipe(
		ofType(submitRegistration),
		mergeMap(async (action) => {
			const {
				avatar,
				firstName,
				lastName,
				invitationId,
				phoneNumber,
				userPoolId,
				username,
				department
			} = action.payload;

			const response = await axios.post(
				`${primaryRestGateway(true)}/registration`,
				{
					avatar,
					firstName,
					lastName,
					invitationId,
					phoneNumber,
					userPoolId,
					username,
					department
				}
			);

			return response.data;
		}),
		switchMap((res) => [submitRegistrationCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Submit Registration Failure', error);

			return merge(of(submitRegistrationFailed(error.message)), source);
		})
	);

export const cancelInvitationEpic = (action$) =>
	action$.pipe(
		ofType(cancelInvitation),
		mergeMap(async (action) => {
			const { invitationId, status } = action.payload;

			const response = await axios.put(
				`${primaryRestGateway()}/api/invitations`,
				{
					invitationId,
					status
				}
			);

			return response.data;
		}),
		switchMap((res) => [cancelInvitationCompleted(res)]),
		catchError((error, source) => {
			Logger.error('Cancel Invitation Failure', error);

			return merge(of(cancelInvitationFailed(error.message)), source);
		})
	);

export default [
	validateInvitationIdEpic,
	submitRegistrationEpic,
	cancelInvitationEpic
];
