import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router';
import { withTranslation } from 'react-i18next';
import DateSelect from '../../inputs/DateSelect';
import OutlinedLoadingButton from '../../buttons/OutlinedLoadingButton';
import ProcessorSelect from '../../processors/ProcessorSelect';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

const DATE_FORMAT = 'YYYY-MM-DD';

export const RepresentmentFilters = (props) => {
	const {
		t,
		dataGetterFunction,
		defaultDaysBack,
		isLoading
	} = props;

	const [searchParams, setSearchParams] = useSearchParams();

	const [processor, setProcessor] = React.useState('');
	const [temporaryProcessor, setTemporaryProcessor] = React.useState();
	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);

	const updateQueryString = (startDate, endDate, processorId) => {
		const params = {
			startDate: dayjs(startDate).format(DATE_FORMAT),
			endDate: dayjs(endDate).format(DATE_FORMAT)
		};

		if (processorId) {
			params.processorId = processorId;
		}

		setSearchParams(params);
	};

	const getData = React.useCallback((startDate, endDate, processorId) => {
		dataGetterFunction({
			startDate,
			endDate,
			processorId
		});

		updateQueryString(startDate, endDate, processorId);
	}, [processor, startDate, endDate]);

	React.useEffect(() => {
		const mappedSearchParams = {};

		searchParams.forEach((value, key) => {
			mappedSearchParams[key] = value;
		});

		if (!isDataEmpty(mappedSearchParams)) {
			getData(mappedSearchParams.startDate, mappedSearchParams.endDate, mappedSearchParams.processorId);

			setStartDate(dayjs(mappedSearchParams.startDate));
			setEndDate(dayjs(mappedSearchParams.endDate));

			if (mappedSearchParams.processorId) {
				setTemporaryProcessor(mappedSearchParams.processorId);
			}
		} else {
			const initialStartDate = dayjs().subtract(defaultDaysBack, 'day');
			const initialEndDate = dayjs();

			setStartDate(initialStartDate);
			setEndDate(initialEndDate);

			updateQueryString(initialStartDate.format(), initialEndDate.format());
		}
	}, [searchParams]);

	const onSubmit = () => {
		updateQueryString(startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT), processor?.id);
	};

	return (
		<Stack direction='column' p={2} mt={2} minWidth={350} component={Paper}>
			<Grid container alignItems='center' spacing={2}>
				<Grid item xs={0} sm={0} md={0} lg={1} sx={{ display: { xs: 'none', lg: 'block' } }} />
				<Grid item xs={12} sm={12} md={12} lg={5}>
					<ProcessorSelect
						value={processor}
						onChange={(newValue) => setProcessor(newValue)}
						dataSource={'processors'}
						translationPath={'representments:filters.processor'}
						temporaryValue={temporaryProcessor}
						setTemporaryValue={setTemporaryProcessor}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={3}>
					<DateSelect
						value={startDate}
						onChange={(newValue) => {
							setStartDate(dayjs(newValue));
						}}
						label={t('representments:filters.startDate')}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={3}>
					<DateSelect
						value={endDate}
						onChange={(newValue) => {
							setEndDate(dayjs(newValue));
						}}
						label={t('representments:filters.endDate')}
					/>
				</Grid>
				<Grid item xs={0} sm={0} md={9} lg={10} sx={{ display: { xs: 'none', md: 'block' } }} />
				<Grid item xs={12} sm={12} md={3} lg={2}>
					<OutlinedLoadingButton onClick={onSubmit} loading={isLoading}>
						{t('common:buttons.submit')}
					</OutlinedLoadingButton>
				</Grid>
			</Grid>
		</Stack>
	);
};

RepresentmentFilters.propTypes = {
	dataGetterFunction: PropTypes.func,
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	defaultDaysBack: PropTypes.number.isRequired,
	t: PropTypes.func
};

export default withTranslation(['common', 'representments'])(RepresentmentFilters);
