import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';

import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import { getMids, getMidsCompleted, getMidsFailed } from './midsSlice';

export const getMerchantsEpic = (action$) =>
	action$.pipe(
		ofType(getMids),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(
				`${primaryRestGateway(true)}/datasources/mids`
			);

			return response.data;
		}),
		switchMap((res) => [getMidsCompleted(res)]),
		catchError((error, source) =>
			merge(of(getMidsFailed(error.message)), source)
		)
	);

export default [getMerchantsEpic];
