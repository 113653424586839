import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import { withTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';

import { rulesActions } from '../../../containers/Rules';

import deepCopy from '../../../utilities/deepCopy';
import UpdateRow from './components/UpdateRow';
import ErrorDisplay from '../shared/ErrorDisplay';
import newRuleConfiguration from '../constants/newRuleConfiguration';
import mapConfigurationForSubmit from '../shared/mappers/mapConfigurationForSubmit';

// Stages
import RuleBuilder from '../shared/stages/RuleBuilder';
import BuildListSelect from '../shared/stages/BuildListSelect';
import NotificationSettingsSelect from '../shared/stages/NotificationSettings/NotificationSettingsSelect';
import RulePropertiesSelect from '../shared/stages/RulePropertiesSelect';

import {
  getUserRule,
  resetUserRule,
  updateUserRule,
  resetUpdateUserRule
} from '../../../containers/userRules/userRulesSlice'

import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const EditRule = ({
  t,
  categoryOptionsError,
  builderOptionsError,
  categoryOptionsAreLoading,
  builderOptionsAreLoading,
  user
}) => {
  const { userRuleId } = useParams();
  const dispatch = useDispatch();
	const navigate = useNavigate();

	const [ruleConfiguration, setRuleConfiguration] = React.useState(
		deepCopy(newRuleConfiguration)
	);
	const [submitErrors, setSubmitErrors] = React.useState();

  const {
    data: userRule,
    isLoading: userRuleIsLoading,
    hasBeenLoaded: userRuleHasBeenLoaded,
    error: userRuleError
  } = useSelector(state => state.userRulesSlice.userRule);
  const {
    isLoading: isUpdatingUserRule,
    hasCompleted: successfullyUpdatedUserRule,
    error: updateUserRuleError
  } = useSelector(state => state.userRulesSlice.updateUserRule);

	React.useEffect(() => {
		if (submitErrors?.length === 0) {
			setSubmitErrors();
		}
	}, [submitErrors]);

	React.useEffect(() => {
		if (userRuleId) {
			dispatch(getUserRule({ userRuleId }));
		}

    return () => {
      dispatch(resetUserRule());
    }
	}, [userRuleId]);

	React.useEffect(() => {
		if (
			userRuleHasBeenLoaded &&
			userRule?.userRuleId === parseInt(userRuleId)
		) {
			const updatedRuleConfiguration = deepCopy(userRule);

			setRuleConfiguration(updatedRuleConfiguration);
		}
	}, [userRule]);

	React.useEffect(() => {
		return () => {
			onResetEdit();
		};
	}, []);

	React.useEffect(() => {
		if (successfullyUpdatedUserRule) {
			dispatch(resetUpdateUserRule());
			navigate('/rules');
		}
	}, [successfullyUpdatedUserRule]);

	const onClickSubmit = () => {
		const updatedRuleConfiguration = mapConfigurationForSubmit(
			user,
			ruleConfiguration
		);

		dispatch(
      updateUserRule({
			  updatedRuleConfiguration,
			  userRuleId
		  }
    ));
	};

	const onResetEdit = () => {
		resetUpdateUserRule();
	};

	const disabledBuilder = userRuleIsLoading || !userRuleHasBeenLoaded || userRuleError ||
		categoryOptionsError !== undefined || builderOptionsError !== undefined;

	return (
		<Stack p={3} spacing={3} sx={{ height: 'calc(100% - 60px)', width: '100%', overflow: 'auto' }}>
			{ruleConfiguration?.userRuleId === undefined && !userRuleError ? (
				<Stack direction='row' alignItems='center' justifyContent='center'>
					<LinearProgress sx={{ width: '100%', maxWidth: '900px' }} />
				</Stack>
			) : null}
			{userRuleError ? (
				<Stack direction='row' alignItems='center' justifyContent='center'>
					<Typography>{t('rules:editRuleError')}</Typography>
				</Stack>
			) : null}
			<ErrorDisplay submitErrors={submitErrors} />
			<RuleBuilder
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={userRuleIsLoading || !userRuleHasBeenLoaded}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<BuildListSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<NotificationSettingsSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<RulePropertiesSelect
				ruleConfiguration={ruleConfiguration}
				setRuleConfiguration={setRuleConfiguration}
				isStageDisabled={disabledBuilder}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
			/>
			<UpdateRow
				ruleConfiguration={ruleConfiguration}
				onClickSubmit={onClickSubmit}
				isUpdatingRule={isUpdatingUserRule}
				updateSuccess={successfullyUpdatedUserRule}
				updateError={updateUserRuleError}
				submitErrors={submitErrors}
				setSubmitErrors={setSubmitErrors}
				isLoading={
					categoryOptionsAreLoading ||
					builderOptionsAreLoading ||
					disabledBuilder
				}
			/>
		</Stack>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.userReducer.get('user'),
		categoryOptionsAreLoading:
			state.ruleBuilderSlice.builderCategories.isLoading,
		categoryOptionsError: state.ruleBuilderSlice.builderCategories.error,
		builderOptionsAreLoading: state.ruleBuilderSlice.builderOptions.isLoading,
		builderOptionsError: state.ruleBuilderSlice.builderOptions.error
	};
};

EditRule.propTypes = {
	t: PropTypes.func,
	user: PropTypes.object,
	categoryOptionsError: PropTypes.string,
	builderOptionsError: PropTypes.string,
	categoryOptionsAreLoading: PropTypes.bool,
	builderOptionsAreLoading: PropTypes.bool,
	updateRule: PropTypes.func
};

export default connect(
	mapStateToProps,
	null
)(withTranslation('rules')(EditRule));
