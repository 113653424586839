import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import makeArray from '../../../utilities/makeArray';
import AffiliateTooltip from '../AffiliateTooltip';

import {
	getAffiliates,
	resetAffiliates
} from '../../../containers/affiliates/affiliatesSlice';

import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const AffiliateSelect = ({
	value,
	onChange,
	t,
	temporaryListValues,
	setTemporaryListValues,
	isDisabled
}) => {
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	const { data, isLoading, hasBeenLoaded, error } = useSelector(
		(state) => state.affiliatesSlice.affiliates
	);

	React.useEffect(() => {
		if (!isLoading && !hasBeenLoaded && !error) {
			dispatch(getAffiliates());
		}
	}, [isLoading, hasBeenLoaded, error]);

	React.useEffect(() => {
		return () => {
			dispatch(resetAffiliates());
		};
	}, []);

	React.useEffect(() => {
		if (open && !isDataEmpty(data)) {
			setOptions(data);
		}
	}, [open, data]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	React.useEffect(() => {
		if (temporaryListValues && !isDataEmpty(data)) {
			const arrifiedListValues = makeArray(temporaryListValues);

			const mappedValues = data.filter((affiliate) => {
				return arrifiedListValues.includes(affiliate.id);
			});

			onChange(mappedValues);
			setTemporaryListValues();
		}
	}, [temporaryListValues, data]);

	const handleOptionSelect = (newValue) => {
		onChange(newValue);
	};

	return (
		<FormControl sx={{ flex: 1 }}>
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				disabled={isDisabled || isLoading}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return option.id === item.id;
				}}
				getOptionLabel={(option) => {
					return option.label;
				}}
				value={value}
				onChange={(event, newValue) => {
					handleOptionSelect(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				renderOption={(props, option) => {
					return (
						<ListItem {...props} key={option.id}>
							<Typography>{option.label}</Typography>
						</ListItem>
					);
				}}
				renderTags={(value) => {
					if (value?.length < 3) {
						return value.map((currentValue) => (
							<Chip
								variant='outlined'
								key={currentValue.id}
								label={currentValue.label}
							/>
						));
					} else {
						return (
							<Tooltip title={<AffiliateTooltip value={value} />}>
								<Chip variant='outlined' label={`${value.length} selected`} />
							</Tooltip>
						);
					}
				}}
				sx={{ flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						variant='outlined'
						label={t('rules:selectAffiliates')}
						helperText={t('common:ctrlHelperText')}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{isLoading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

AffiliateSelect.propTypes = {
	value: PropTypes.array,
	onChange: PropTypes.func,
	t: PropTypes.func,
	temporaryListValues: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	setTemporaryListValues: PropTypes.func,
	isDisabled: PropTypes.bool
};

export default withTranslation(['rules', 'common'])(AffiliateSelect);
