import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { tableauActions } from '../../../containers/Tableau';
import { insightsActions } from '../../../containers/Insights';
import { tableauServer } from '../../../utilities/apiEndpointUtility';
import PropTypes from 'prop-types';
import Header from './Header';
import tableauTimeout from '../../../constants/tableauTimeout';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import isTokenExpired from '../../../helpers/tableau/isTokenExpired';
import TableauFilters from './TableauFilters';

import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import ActionRow from '../TableauWrapper/ActionRow';

const TableauInsightWrapper = (props) => {
	const {
		generateToken,
		getInsightReport,
		insightReport,
		insightReportIsLoading,
		resetToken,
		resetInsightReport,
		siteId,
		token,
		tokenIsLoading,
		tokenExpiration
	} = props;

	const tableauRef = React.useRef(null);

	const [vizSetupCompleted, setVizSetupCompleted] = React.useState(false);

	const location = useLocation();

	React.useEffect(() => {
		if (!insightReportIsLoading) {
			const reportId = window.location.pathname.split('/')[2];

			getInsightReport({
				reportId,
				queryParameters: window.location.search
			});
		}
	}, [location]);

	React.useEffect(() => {
		if (!token && isTokenExpired(tokenExpiration) && !tokenIsLoading) {
			generateToken();
		}

		return () => {
			resetToken();
			resetInsightReport();
		};
	}, []);

	React.useEffect(() => {
		if (
			!vizSetupCompleted &&
			siteId &&
			!isDataEmpty(insightReport) &&
			!isTokenExpired(tokenExpiration)
		) {
			setVizSetupCompleted(true);
		} else if (vizSetupCompleted) {
			const reportUrl = window.location.pathname.split('/').slice(3).join('/');

			const viz = document.getElementById('tableau-viz-insights');

			if (viz) {
				viz.src = `${tableauServer()}${siteId}/${reportUrl}`;
				viz.token = token;
			}
		}
	}, [insightReport, token, siteId, vizSetupCompleted]);

	React.useEffect(() => {
		const runner = () => {
			if (tableauRef.current) {
				generateToken();
			}
			setTimeout(() => {
				runner();
			}, tableauTimeout.timeoutInMilliseconds);
		};

		runner();
	}, [tableauRef]);

	const exportDataHandler = async () => {
		const viz = document.getElementById('tableau-viz-insights');

		await viz.displayDialogAsync('export-cross-tab');
	};

	if (!vizSetupCompleted) {
		return (
			<Stack
				direction='column'
				justifyContent='center'
				alignItems='center'
				sx={{ width: '100%', height: '100%' }}
			>
				<CircularProgress />
			</Stack>
		);
	}

	return (
		<Stack sx={{ width: '100%', height: 800 }}>
			<Stack direction='row' justifyContent='space-between'>
				<Header
					appliedTo={insightReport.appliedTo}
					userRuleDescription={insightReport.userRuleDescription}
					name={insightReport.name}
				/>
				<ActionRow downloadReport={exportDataHandler} />
			</Stack>
			<tableau-viz
				id='tableau-viz-insights'
				ref={tableauRef}
				style={{
					width: '100%',
					height: '100%'
				}}
				toolbar='hidden'
			>
				<TableauFilters
					filters={insightReport.filters}
					parameters={insightReport.parameters}
				/>
			</tableau-viz>
		</Stack>
	);
};

TableauInsightWrapper.propTypes = {
	getInsightReport: PropTypes.func,
	insightReport: PropTypes.shape({
		appliedTo: PropTypes.string,
		userRuleDescription: PropTypes.string,
		name: PropTypes.string,
		filters: PropTypes.array,
		parameters: PropTypes.array
	}),
	insightReportIsLoading: PropTypes.bool,
	generateToken: PropTypes.func,
	resetToken: PropTypes.func,
	resetInsightReport: PropTypes.func,
	siteId: PropTypes.string,
	token: PropTypes.string,
	tokenIsLoading: PropTypes.bool,
	tokenExpiration: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		insightReport: state.insightsReducer.get('data'),
		insightReportIsLoading: state.insightsReducer.get('isLoading'),
		siteId: state.tableauReducer.get('siteId'),
		token: state.tableauReducer.get('token'),
		tokenIsLoading: state.tableauReducer.get('isLoading'),
		tokenExpiration: state.tableauReducer.get('expiration')
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		generateToken: (payload) => {
			dispatch(tableauActions.generateToken(payload));
		},
		getInsightReport: (payload) => {
			dispatch(insightsActions.getInsightReport(payload));
		},
		resetToken: (payload) => {
			dispatch(tableauActions.resetToken(payload));
		},
		resetInsightReport: (payload) => {
			dispatch(insightsActions.resetInsightReport(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableauInsightWrapper);
