import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { withTranslation } from 'react-i18next';
import ContainedButton from '../buttons/ContainedButton';
import BoldLargeHeader from '../headers/BoldLargeHeader';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ConfirmationForm = (props) => {
	const { t, hasBeenRegistered, isRegistering } = props;

	const navigate = useNavigate();

	if (!hasBeenRegistered || isRegistering) {
		return (
			<Box>
				<Stack direction='row' justifyContent='center' my={2} sx={{ px: 4 }}>
					<BoldLargeHeader>{t('registrationForm.registering')}</BoldLargeHeader>
				</Stack>
				<Stack direction='row' justifyContent='center' p={2} mx={4}>
					<CircularProgress />
				</Stack>
			</Box>
		);
	}

	return (
		<Box sx={{ maxWidth: 400 }}>
			<Stack direction='row' justifyContent='center' my={2} sx={{ px: 4 }}>
				<BoldLargeHeader>{t('registrationForm.success')}</BoldLargeHeader>
			</Stack>
			<Box p={2} mx={1}>
				<Typography variant='body1' sx={{ textAlign: 'center' }}>
					{t('registrationForm.temporaryPassword')}
				</Typography>
			</Box>
			<Stack direction='row' justifyContent='center' mb={3}>
				<ContainedButton onClick={() => navigate('/login')}>
					{t('registrationForm.backToLogin')}
				</ContainedButton>
			</Stack>
		</Box>
	);
};

ConfirmationForm.propTypes = {
	hasBeenRegistered: PropTypes.bool,
	isRegistering: PropTypes.bool,
	t: PropTypes.func
};

export default withTranslation('registration')(ConfirmationForm);
