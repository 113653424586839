import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelectChipTooltip from '../../shared/MultiSelectChipTooltip';
import keyCodes from '../../../constants/keyCodes';
import SubtextError from '../../errors/SubtextError';

import {
	getMerchantCategoryCodes,
	resetMerchantCategoryCodes
} from '../../../containers/merchants/merchantCategoryCodesSlice';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const MerchantCategoryCodeSelect = ({
	t,
	onChange,
	value,
	listItemsIndividually
}) => {
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);

	const {
		data: merchantCategoryCodes,
		isLoading: merchantCategoryCodesAreLoading,
		hasBeenLoaded: merchantCategoryCodesHaveBeenLoaded,
		error: merchantCategoryCodesError
	} = useSelector(
		(state) => state.merchantCategoryCodesSlice.merchantCategoryCodes
	);

	React.useEffect(() => {
		dispatch(getMerchantCategoryCodes());

		return () => {
			return dispatch(resetMerchantCategoryCodes());
		};
	}, []);

	React.useEffect(() => {
		if (open && merchantCategoryCodesHaveBeenLoaded) {
			setOptions(merchantCategoryCodes ?? []);
		}
	}, [open, merchantCategoryCodes]);

	React.useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<FormControl sx={{ flex: 1 }}>
			{merchantCategoryCodesError ? (
				<Box pl={1}>
					<SubtextError
						errorText={t('inputs:unableToLoadMerchantCategoryCodes')}
					/>
				</Box>
			) : null}
			<Autocomplete
				multiple
				filterSelectedOptions
				options={options}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={merchantCategoryCodesAreLoading}
				disabled={
					!merchantCategoryCodesHaveBeenLoaded ||
					merchantCategoryCodesAreLoading
				}
				isOptionEqualToValue={(option, item) => {
					return option.merchantCategoryCode === item.merchantCategoryCode;
				}}
				getOptionLabel={(option) => option?.merchantCategoryCode}
				renderTags={
					!listItemsIndividually
						? (value) => (
								<Tooltip
									title={
										<MultiSelectChipTooltip
											value={value}
											idKey={'merchantCategoryCode'}
											labelKey={'merchantCategoryCode'}
										/>
									}
								>
									<Chip variant='outlined' label={`${value.length} selected`} />
								</Tooltip>
							)
						: undefined
				}
				renderOption={(props, option) => {
					return (
						<ListItem {...props} key={option.merchantCategoryCode}>
							<Typography>{option.merchantCategoryCode}</Typography>
						</ListItem>
					);
				}}
				value={value}
				onChange={(event, newValue, reason) => {
					if (
						reason !== 'removeOption' ||
						event.keyCode !== keyCodes.backspace
					) {
						onChange(newValue);
					}
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				sx={{ ml: 1, flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						label={t('inputs:selectMerchantCategoryCodes')}
						variant='outlined'
						helperText={t('inputs:ctrlHelperText')}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{merchantCategoryCodesAreLoading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

MerchantCategoryCodeSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.array,
	t: PropTypes.func,
	temporaryGroupValues: PropTypes.array,
	setTemporaryGroupValues: PropTypes.func,
	listItemsIndividually: PropTypes.bool
};

export default withTranslation(['inputs'])(MerchantCategoryCodeSelect);
