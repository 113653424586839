import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getGroups,
	getGroupsCompleted,
	getGroupsFailed,
	getGroup,
	getGroupCompleted,
	getGroupFailed,
	addGroup,
	addGroupCompleted,
	addGroupFailed,
	updateGroup,
	updateGroupCompleted,
	updateGroupFailed,
	deleteGroup,
	deleteGroupCompleted,
	deleteGroupFailed
} from './groupsSlice';

export const getGroupsEpic = (action$) =>
	action$.pipe(
		ofType(getGroups),
		mergeMap(async (action) => {
			await refreshToken();

			const groups = await axios.get(`${primaryRestGateway(true)}/groups`);

			return groups;
		}),
		switchMap((res) => [getGroupsCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(getGroupsFailed(error.message)), source)
		)
	);

export const getGroupEpic = (action$) =>
	action$.pipe(
		ofType(getGroup),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupId } = action.payload;

			const group = await axios.get(
				`${primaryRestGateway(true)}/groups/${groupId}`
			);

			return group;
		}),
		switchMap((res) => [getGroupCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(getGroupFailed(error.message)), source)
		)
	);

export const addGroupEpic = (action$) =>
	action$.pipe(
		ofType(addGroup),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupDefinition } = action.payload;

			const res = await axios.post(
				`${primaryRestGateway(true)}/groups`,
				groupDefinition
			);

			return res;
		}),
		switchMap((res) => [addGroupCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(addGroupFailed(error.message)), source)
		)
	);

export const updateGroupEpic = (action$) =>
	action$.pipe(
		ofType(updateGroup),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupDefinition, groupId } = action.payload;

			const res = await axios.put(
				`${primaryRestGateway(true)}/groups/${groupId}`,
				groupDefinition
			);

			return res;
		}),
		switchMap((res) => [updateGroupCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(updateGroupFailed(error.message)), source)
		)
	);

export const deleteGroupEpic = (action$) =>
	action$.pipe(
		ofType(deleteGroup),
		mergeMap(async (action) => {
			await refreshToken();

			const { groupId } = action.payload;

			const res = await axios.delete(
				`${primaryRestGateway(true)}/groups/${groupId}`
			);

			return res;
		}),
		switchMap((res) => [deleteGroupCompleted(res.data)]),
		catchError((error, source) =>
			merge(of(deleteGroupFailed(error.message)), source)
		)
	);

export default [
	getGroupsEpic,
	getGroupEpic,
	addGroupEpic,
	updateGroupEpic,
	deleteGroupEpic
];
