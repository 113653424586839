import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PageError from '../errors/PageError';
import { supportActions } from '../../containers/Support';
import BorderedPaper from '../branded/BorderedPaper';
import BoldLargeHeader from '../headers/BoldLargeHeader';
import ContainedLoadingButton from '../buttons/ContainedLoadingButton';

import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const SupportForm = (props) => {
	const {
		t,
		isSubmitting,
		hasBeenSuccessfullySubmitted,
		error
	} = props;

	const [shortDescription, setShortDescription] = React.useState('');
	const [stepsToReproduce, setStepsToReproduce] = React.useState('');
	const [additionalInformation, setAdditionalInformation] = React.useState('');

	const navigate = useNavigate();

	React.useEffect(() => {
		if (hasBeenSuccessfullySubmitted) {
			navigate('/support/success');
		}
	}, [hasBeenSuccessfullySubmitted]);

	const onSubmit = () => {
		const { submitSupportForm } = props;

		submitSupportForm({
			shortDescription,
			stepsToReproduce,
			additionalInformation
		});
	};

	if (error) {
		return <PageError />;
	}

	return (
		<Stack direction='row' justifyContent='center'>
			<BorderedPaper sx={{ my: 1.5, maxWidth: '900px' }}>
				<Stack direction='column' p={4} spacing={2}>
					<BoldLargeHeader>
						{t('support:supportForm.label')}
					</BoldLargeHeader>
					<FormControl variant='standard'>
						<label htmlFor={'short-description-input'}>
							{t('support:supportForm.shortDescription')}
						</label>
						<TextField
							id='short-description-input'
							required={true}
							autoFocus={true}
							value={shortDescription}
							onChange={(e) => {
								setShortDescription(e.target.value);
							}}
							helperText={t('support:supportForm.maxCharacters')}
						/>
					</FormControl>
					<FormControl variant='standard'>
						<label htmlFor={'steps-to-reproduce-input'}>
							{t('support:supportForm.stepsToReproduce')}
						</label>
						<TextField
							id='steps-to-reproduce-input'
							multiline
							required={true}
							fullWidth={true}
							value={stepsToReproduce}
							onChange={(e) => {
								setStepsToReproduce(e.target.value);
							}}
							helperText={t('support:supportForm.stepsToReproducerHelperText')}
						/>
					</FormControl>
					<FormControl variant='standard'>
						<label htmlFor={'additional-information-input'}>
							{t('support:supportForm.additionalInformation')}
						</label>
						<TextField
							id='additional-information-input'
							multiline
							value={additionalInformation}
							fullWidth={true}
							maxRows={10}
							size='medium'
							onChange={(e) => {
								setAdditionalInformation(e.target.value);
							}}
							helperText={t('support:supportForm.optional')}
						/>
					</FormControl>
					<Stack
						direction='row'
						justifyContent='flex-end'
						alignItems='center'
						p={1}
					>
						<ContainedLoadingButton
							onClick={onSubmit}
							loading={isSubmitting}
						>
							{t('common:buttons.submit')}
						</ContainedLoadingButton>
					</Stack>
				</Stack>
			</BorderedPaper>
		</Stack>
	);
};

SupportForm.propTypes = {
	isSubmitting: PropTypes.bool,
	hasBeenSuccessfullySubmitted: PropTypes.bool,
	error: PropTypes.string,
	submitSupportForm: PropTypes.func,
	t: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isSubmitting: state.supportReducer.getIn(['supportForm', 'isSubmitting']),
		hasBeenSuccessfullySubmitted: state.supportReducer.getIn([
			'supportForm',
			'hasBeenSuccessfullySubmitted'
		]),
		error: state.supportReducer.getIn(['supportForm', 'error'])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		submitSupportForm: (payload) => {
			dispatch(supportActions.submitSupportForm(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation(['common', 'support'])(SupportForm));
