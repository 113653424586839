import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withSize } from 'react-sizeme';
import { Outlet } from 'react-router';
import SideNavigation from './SideNavigation';
import Header from './Header';
import Loader from '../../Loader';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const Layout = (props) => {
	const { userHasBeenLoaded, size } = props;

	const dashboardRef = React.useRef(null);

	if (!userHasBeenLoaded) {
		return <Loader />;
	}

	return (
		<Container
			maxWidth={false}
			disableGutters
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				overflow: 'hidden',
				bgcolor: 'common.white'
			}}
		>
			<Header />
			<Box sx={{ display: 'flex', height: size.height - 80 }}>
				<SideNavigation dashboardRef={dashboardRef} />
				<Box
					ref={dashboardRef}
					sx={{ width: '100%', height: size.height - 80, overflow: 'auto' }}
				>
					<Outlet />
				</Box>
			</Box>
		</Container>
	);
};

Layout.propTypes = {
	userHasBeenLoaded: PropTypes.bool,
	size: PropTypes.object
};

const mapStateToProps = (state) => {
	return { userHasBeenLoaded: state.userReducer.get('userHasBeenLoaded') };
};

export default connect(
	mapStateToProps,
	null
)(withSize({ monitorHeight: true })(Layout));
