import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import makeArray from '../../../utilities/makeArray';

import { getGroups } from '../../../containers/groups/groupsSlice';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const GroupSelect = ({
	value,
	onChange,
	t,
	temporaryListValues,
	setTemporaryListValues,
	isDisabled
}) => {
	const dispatch = useDispatch();

	const [inputValue, setInputValue] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const loading = options.length === 0 && open;

	const {
		data: groups,
		isLoading: groupsAreLoading,
		hasBeenLoaded: groupsHaveBeenLoaded,
		error: groupsError
	} = useSelector((state) => state.groupsSlice.groups);

	React.useEffect(() => {
		if (open) {
			dispatch(getGroups());
		}

		setOptions([]);
	}, [open]);

	React.useEffect(() => {
		if (temporaryListValues && !isDataEmpty(groups)) {
			const arrifiedListValues = makeArray(temporaryListValues);

			const mappedValues = groups.filter((group) => {
				return arrifiedListValues.includes(group.id);
			});

			onChange(mappedValues);
			setTemporaryListValues();
		}
	}, [temporaryListValues, groups]);

	React.useEffect(() => {
		if (open && !isDataEmpty(groups)) {
			setOptions(groups);
		}
	}, [open, groups]);

	const handleOptionSelect = (newValue) => {
		onChange(newValue);
	};

	return (
		<FormControl sx={{ flex: 1 }}>
			<Autocomplete
				filterSelectedOptions
				options={options}
				disabled={isDisabled || groupsAreLoading}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				loading={loading}
				isOptionEqualToValue={(option, item) => {
					return option.id === item.id;
				}}
				getOptionLabel={(option) => {
					return option?.groupLabel ?? option?.label ?? '';
				}}
				value={value ?? ''}
				onChange={(event, newValue) => {
					handleOptionSelect(newValue);
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				renderOption={(props, option) => {
					return (
						<ListItem {...props} key={option.id}>
							<Stack direction='column'>
								<Typography>{option?.groupLabel}</Typography>
							</Stack>
						</ListItem>
					);
				}}
				sx={{ flex: 1 }}
				renderInput={(params) => (
					<TextField
						{...params}
						variant='outlined'
						label={t('groups:selectGroup')}
						helperText={t('common:ctrlHelperText')}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{groupsAreLoading ? (
										<CircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

GroupSelect.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
		PropTypes.number
	]),
	t: PropTypes.func,
	temporaryListValues: PropTypes.array,
	setTemporaryListValues: PropTypes.func,
	isDisabled: PropTypes.bool,
	classes: PropTypes.object
};

export default withTranslation(['groups', 'common'])(GroupSelect);
