import React from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import PageHeader from '../../../components/headers/PageHeader';
import Restricted from '../../../permissionProvider/Restricted';
import RestrictedFallback from '../../../components/shared/RestrictedFallback';
import RepresentmentFilters from '../../../components/Representments/RepresentmentFilters';
import SortableTable from '../../../components/SortableTable';
import EnhancedTableToolbar from '../../../components/Representments/NotChallenged/NotChallengedTable/EnhancedTableToolbar';
import NotChallengedTableRow from '../../../components/Representments/NotChallenged/NotChallengedTable/NotChallengedTableRow';
import searchableColumns from '../../../components/Representments/NotChallenged/NotChallengedTable/constants/searchableColumns';
import columnDefinitions from '../../../components/Representments/NotChallenged/NotChallengedTable/constants/columnDefinitions';
import ViewDetailsModal from '../../../components/Representments/NotChallenged/ViewDetailsModal';
import SubtextError from '../../../components/errors/SubtextError';
import isDataEmpty from '../../../helpers/shared/isDataEmpty';
import * as sorting from '../../../constants/sorting';
import {
	getNotChallenged,
	revertNotChallenged,
	resetRevertNotChallenged
} from '../../../containers/Representments/notChallengedSlice';
import { representmentActions } from '../../../containers/Representment';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

const DEFAULT_DAYS_BACK = 30;

export const NotChallengedPage = ({
	t,
	data,
	isLoading,
	hasBeenLoaded,
	error,
	revertNotChallenged,
	representment,
	representmentIsLoading,
	representmentHasBeenLoaded,
	representmentError,
	getRepresentment,
	getNotChallenged,
	revertIdList,
	revertError
}) => {
	const [searchParams] = useSearchParams();

	const [searchTerm, setSearchTerm] = React.useState('');
	const [representmentId, setRepresentmentId] = React.useState();

	React.useEffect(() => {
		if (representmentId) {
			getRepresentment({
				chargebackId: representmentId
			});
		}
	}, [representmentId]);

	React.useEffect(() => {
		if (!isDataEmpty(data) && !isDataEmpty(revertIdList) && !isLoading) {
			resetRevertNotChallenged();
		}
	}, [data, revertIdList, isLoading, resetRevertNotChallenged]);

	// TODO look into putting this func into the row itself
	const onRevertToOpportunities = (caseId) => {
		const startDate = searchParams.get('startDate');
		const endDate = searchParams.get('endDate');
		const processorId = searchParams('processorId');

		revertNotChallenged({
			startDate: startDate || dayjs().add(-30, 'day').format(),
			endDate: endDate || dayjs().format(),
			processorId,
			chargebackIdList: [caseId]
		});
	};

	return (
		<Container maxWidth={false}>
			<Restricted
				to='representments.notChallenged.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('error.notAllowed')} />
				}
			>
				<Stack spacing={2} p={2}>
					<PageHeader label={t('representments:uncontested')} />
					<RepresentmentFilters
						isLoading={isLoading}
						hasBeenLoaded={hasBeenLoaded}
						dataGetterFunction={getNotChallenged}
						defaultDaysBack={DEFAULT_DAYS_BACK}
					/>
					{revertError ? (
						<SubtextError errorText={t('representments:failedToRevert')} />
					) : null}
					<EnhancedTableToolbar
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
					/>
					<SortableTable
						data={data}
						isLoading={isLoading}
						hasBeenLoaded={hasBeenLoaded}
						error={error}
						initialSortColumn={'notChallengedOn'}
						columnDefinitions={columnDefinitions}
						TableRowComponent={NotChallengedTableRow}
						translationContext={'representments'}
						searchTerm={searchTerm}
						rowProps={{
							onViewRepresentment: setRepresentmentId,
							onRevertToOpportunities,
							revertIdList
						}}
						searchableColumns={searchableColumns}
						defaultSortOrder={sorting.sortOrder.desc}
					/>
					<ViewDetailsModal
						isOpen={!!representmentId}
						onCloseModal={() => {
							setRepresentmentId();
						}}
						representment={representment}
						representmentIsLoading={representmentIsLoading}
						representmentHasBeenLoaded={representmentHasBeenLoaded}
						representmentError={representmentError}
					/>
				</Stack>
			</Restricted>
		</Container>
	);
};

NotChallengedPage.propTypes = {
	t: PropTypes.func,
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	isLoading: PropTypes.bool,
	hasBeenLoaded: PropTypes.bool,
	error: PropTypes.string,
	getRepresentment: PropTypes.func,
	revertNotChallenged: PropTypes.func,
	representment: PropTypes.object,
	representmentIsLoading: PropTypes.bool,
	representmentHasBeenLoaded: PropTypes.bool,
	representmentError: PropTypes.string,
	getNotChallenged: PropTypes.func,
	revertIdList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	successfullyCompleted: PropTypes.bool,
	revertError: PropTypes.string,
	resetRevertNotChallenged: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		isLoading: state.notChallengedSlice.notChallenged.isLoading,
		hasBeenLoaded: state.notChallengedSlice.notChallenged.hasBeenLoaded,
		error: state.notChallengedSlice.notChallenged.error,
		data: state.notChallengedSlice.notChallenged.data,
		revertIdList: state.notChallengedSlice.revertNotChallenged.revertIdList,
		successfullyCompleted:
			state.notChallengedSlice.revertNotChallenged.successfullyCompleted,
		revertError: state.notChallengedSlice.revertNotChallenged.error,
		representment: state.representmentReducer.getIn(['representment', 'data']),
		representmentIsLoading: state.representmentReducer.getIn([
			'representment',
			'isLoading'
		]),
		representmentHasBeenLoaded: state.representmentReducer.getIn([
			'representment',
			'hasBeenLoaded'
		]),
		representmentError: state.representmentReducer.getIn([
			'representment',
			'error'
		])
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		revertNotChallenged: (payload) => {
			dispatch(revertNotChallenged(payload));
		},
		getRepresentment: (payload) => {
			dispatch(representmentActions.getRepresentment(payload));
		},
		getNotChallenged: (payload) => {
			dispatch(getNotChallenged(payload));
		},
		resetRevertNotChallenged: (payload) => {
			dispatch(resetRevertNotChallenged(payload));
		}
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('representments', 'common')(NotChallengedPage));
