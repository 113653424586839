import config from '../config.json';

export function serviceApiEndpoint() {
	return config[process.env.REACT_APP_STAGE].api.servicesApi;
}

export function primaryRestGateway(useApiEndpoint) {
	if (useApiEndpoint) {
		return config[process.env.REACT_APP_STAGE].api.primaryRestGateway;
	} else {
		return config[process.env.REACT_APP_STAGE].lambda.primaryLambdaGateway;
	}
}

export function secondaryRestGateway(useApiEndpoint) {
	if (useApiEndpoint) {
		return config[process.env.REACT_APP_STAGE].api.secondaryRestGateway;
	} else {
		return config[process.env.REACT_APP_STAGE].lambda.secondaryLambdaGateway;
	}
}

export function pdfEndpoint() {
	return config[process.env.REACT_APP_STAGE].pdfURI;
}

export function landingPageURI() {
	return config[process.env.REACT_APP_STAGE].landingPageURI;
}

export function tableauServer() {
	return config[process.env.REACT_APP_STAGE].tableauServer;
}

export function getRulesRunnerEndpoint() {
	return config[process.env.REACT_APP_STAGE].rulesRunnerApi;
}

export function getWebsocketGatewayEndpoint() {
	return config[process.env.REACT_APP_STAGE].websocketGateway;
}
