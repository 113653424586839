import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import My360Header from '../../components/my360/Header';
import GroupManager from '../../components/groups/GroupManager';
import Restricted from '../../permissionProvider/Restricted';
import RestrictedFallback from '../../components/shared/RestrictedFallback';

import Stack from '@mui/material/Stack';

export const SavedGroupsPage = ({ t }) => {
	return (
		<Stack p={1}>
			<Restricted
				to='groups.groups.view'
				Fallback={
					<RestrictedFallback overrideMessage={t('common:error.notAllowed')} />
				}
			>
				<My360Header label={t('groups:viewExistingGroups')} />
				<GroupManager />
			</Restricted>
		</Stack>
	);
};

SavedGroupsPage.propTypes = {
	t: PropTypes.func
};

export default withTranslation(['groups', 'common'])(SavedGroupsPage);
