import axios from '../../utilities/axios';
import { ofType } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { refreshToken } from '../../utilities/refreshToken';
import { primaryRestGateway } from '../../utilities/apiEndpointUtility';

import {
	getProcessors,
	getProcessorsCompleted,
	getProcessorsFailed
} from './processorsSlice';

export const getProcessorsEpic = (action$) =>
	action$.pipe(
		ofType(getProcessors),
		mergeMap(async (action) => {
			await refreshToken();

			const response = await axios.get(`${primaryRestGateway(true)}/datasources/processors`);

			return response.data;
		}),
		switchMap((res) => [getProcessorsCompleted(res)]),
		catchError((error, source) =>
			merge(of(getProcessorsFailed(error?.message)), source)
		)
	);

export default [
	getProcessorsEpic
];
