export default function (data) {
	let result = false;

	if (data === undefined) {
		result = true;
	} else if (data && Array.isArray(data) && data.length < 1) {
		result = true;
	} else if (data && Object.keys(data).length < 1) {
		result = true;
	} else if (data.size === 0) {
		result = true;
	} else if (!data) {
		result = true;
	} else if (data === '') {
		result = true;
	}

	return result;
}
