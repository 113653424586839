import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { catchError } from 'rxjs/operators';

import { authEpics, authReducer } from '../containers/Auth';
import { dataSourceEpics, dataSourceReducer } from '../containers/DataSource';
import { insightsEpics, insightsReducer } from '../containers/Insights';
import { layoutEpics, layoutReducer } from '../containers/Layouts';
import { modalReducer } from '../containers/Modal';
import { multiFactorAuthEpics, multiFactorAuthReducer } from '../containers/MultiFactorAuth';
import { organizationEpics, organizationReducer } from '../containers/Organization';
import { permissionsEpics, permissionsReducer } from '../containers/Permissions';
import { reporterEpics, reporterReducer } from '../containers/Reporter';
import { representmentEpics, representmentReducer } from '../containers/Representment';
import { representmentsEpics, representmentsReducer } from '../containers/Representments';
import { rulesEpics, rulesReducer } from '../containers/Rules';
import { servicePdfEpics, servicePdfReducer } from '../containers/ServicePdf';
import { supportEpics, supportReducer } from '../containers/Support';
import { systemEpic, systemReducer } from '../containers/System';
import { tableauEpic, tableauReducer } from '../containers/Tableau';
import { userEpics, userReducer } from '../containers/User';
import { usersEpic, usersReducer } from '../containers/Users';

// SLICES
import affiliatesSlice from '../containers/affiliates/affiliatesSlice';
import groupsSlice from '../containers/groups/groupsSlice';
import midsSlice from '../containers/merchants/midsSlice';
import merchantCategoryCodesSlice from '../containers/merchants/merchantCategoryCodesSlice';
import notChallengedSlice from '../containers/Representments/notChallengedSlice';
import notificationsSlice from '../containers/notifications/notificationsSlice';
import sideNavigationSlice from '../containers/sideNavigation/sideNavigationSlice';
import opportunitiesSlice from '../containers/Representments/opportunitiesSlice';
import processorsSlice from '../containers/processors/processorsSlice';
import registrationSlice from '../containers/registration/registrationSlice';
import reportsSlice from '../containers/Reports/reportsSlice';
import reportViewsSlice from '../containers/reportViews/reportViewsSlice';
import representmentReportsSlice from '../containers/Representments/representmentReportSlice';
import resolvedSlice from '../containers/Representments/resolvedSlice';
import ruleBuilderSlice from '../containers/Rules/ruleBuilderSlice';
import rulesRunnerTokenSlice from '../containers/RulesRunner/rulesRunnerTokenSlice';
import rulesRunnerTrackedSlice from '../containers/RulesRunner/rulesRunnerTrackedSlice';
import rulesRunnerUntrackedSlice from '../containers/RulesRunner/rulesRunnerUntrackedSlice';
import userRulesSlice from '../containers/userRules/userRulesSlice';
import userAvatarSlice from '../containers/User/userAvatarSlice';
import userRuleSlice from '../containers/Rules/userRuleSlice';
import usersSlice from '../containers/Users/usersSlice';

// EPICS
import affiliatesEpics from '../containers/affiliates/affiliatesEpics';
import groupsEpics from '../containers/groups/groupsEpic';
import midsEpics from '../containers/merchants/midsEpics';
import merchantCategoryCodesEpics from '../containers/merchants/merchantCategoryCodesEpics';
import notChallengedEpics from '../containers/Representments/notChallengedEpics';
import notificationsEpics from '../containers/notifications/notificationsEpics';
import opportunitiesEpics from '../containers/Representments/opportunitiesEpics';
import processorsEpics from '../containers/processors/processorsEpics';
import registrationEpics from '../containers/registration/registrationEpics';
import reportsEpics from '../containers/Reports/reportsEpics';
import reportViewsEpics from '../containers/reportViews/reportViewsEpics';
import representmentReportsEpics from '../containers/Representments/representmentReportsEpics';
import resolvedEpics from '../containers/Representments/resolvedEpics';
import rulesRunnerTokenEpics from '../containers/RulesRunner/rulesRunnerTokenEpics';
import rulesRunnerTrackedEpics from '../containers/RulesRunner/rulesRunnerTrackedEpics';
import rulesRunnerUntrackedEpics from '../containers/RulesRunner/rulesRunnerUntrackedEpics';
import userRulesEpics from '../containers/userRules/userRulesEpics';
import userRuleEpics from '../containers/Rules/userRuleEpics';
import usersEpics from '../containers/Users/usersEpics';

const epics = combineEpics(
	...affiliatesEpics,
	...authEpics,
	...dataSourceEpics,
	...groupsEpics,
	...insightsEpics,
	...layoutEpics,
	...midsEpics,
	...merchantCategoryCodesEpics,
	...multiFactorAuthEpics,
	...notChallengedEpics,
	...notificationsEpics,
	...opportunitiesEpics,
	...organizationEpics,
	...permissionsEpics,
	...processorsEpics,
	...registrationEpics,
	...reporterEpics,
	...reportViewsEpics,
	...representmentEpics,
	...representmentsEpics,
	...representmentReportsEpics,
	...reportsEpics,
	...resolvedEpics,
	...rulesEpics,
	...rulesRunnerTokenEpics,
	...rulesRunnerTrackedEpics,
	...rulesRunnerUntrackedEpics,
	...userRulesEpics,
	...servicePdfEpics,
	...supportEpics,
	...systemEpic,
	...tableauEpic,
	...userEpics,
	...userRuleEpics,
	...usersEpic,
	...usersEpics
);

const rootReducer = combineReducers({
	affiliatesSlice,
	authReducer,
	dataSourceReducer,
	insightsReducer,
	groupsSlice,
	layoutReducer,
	midsSlice,
	merchantCategoryCodesSlice,
	modalReducer,
	multiFactorAuthReducer,
	notChallengedSlice,
	notificationsSlice,
	processorsSlice,
	organizationReducer,
	opportunitiesSlice,
	permissionsReducer,
	registrationSlice,
	reportViewsSlice,
	reporterReducer,
	representmentReducer,
	representmentsReducer,
	representmentReportsSlice,
	reportsSlice,
	resolvedSlice,
	rulesReducer,
	ruleBuilderSlice,
	rulesRunnerTokenSlice,
	rulesRunnerTrackedSlice,
	rulesRunnerUntrackedSlice,
	userRulesSlice,
	sideNavigationSlice,
	servicePdfReducer,
	supportReducer,
	systemReducer,
	tableauReducer,
	userReducer,
	userAvatarSlice,
	usersReducer,
	userRuleSlice,
	usersSlice
});

const rootEpic = (action$, store$, dependencies) =>
	epics(action$, store$, dependencies).pipe(
		catchError((error, source) => {
			console.error('Root Epic Error Catcher', error);
			return source;
		})
	);

export { rootEpic, rootReducer };
