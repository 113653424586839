import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

const Tooltip = ({ value = [], idKey, labelKey }) => {
	const validatedEntries = value.filter((entry) => entry[idKey]);

	return (
		<React.Fragment>
			{validatedEntries.map((entry) => {
				return (
					<Typography
						key={entry[idKey]}
						variant='caption'
						paragraph={true}
						m={0}
					>
						{entry[labelKey]}
					</Typography>
				);
			})}
		</React.Fragment>
	);
};

Tooltip.propTypes = {
	value: PropTypes.array
};

export default Tooltip;
