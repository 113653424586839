import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	merchantCategoryCodes: {
		data: [],
		isLoading: false,
		hasBeenLoaded: false,
		error: undefined
	}
};

export const merchantCategoryCodesSlice = createSlice({
	name: 'merchantCategoryCodes',
	initialState,
	reducers: {
		getMerchantCategoryCodes: (state) => {
			state.merchantCategoryCodes.isLoading = true;
			state.merchantCategoryCodes.error = undefined;
		},
		getMerchantCategoryCodesCompleted: (state, action) => {
			state.merchantCategoryCodes.isLoading = false;
			state.merchantCategoryCodes.hasBeenLoaded = true;
			state.merchantCategoryCodes.data = action.payload;
		},
		getMerchantCategoryCodesFailed: (state, action) => {
			state.merchantCategoryCodes.isLoading = false;
			state.merchantCategoryCodes.error = action.payload;
		},
		resetMerchantCategoryCodes: (state) => {
			state.merchantCategoryCodes = initialState.merchantCategoryCodes;
		}
	}
});

export const {
	getMerchantCategoryCodes,
	getMerchantCategoryCodesCompleted,
	getMerchantCategoryCodesFailed,
	resetMerchantCategoryCodes
} = merchantCategoryCodesSlice.actions;

export default merchantCategoryCodesSlice.reducer;
