import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import notificationUpdateTypes from '../../../constants/notificationUpdateTypes';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

export const NotificationUpdateTypeSelect = ({
	t,
	selectedUpdateType,
	onChangeSelection,
	useSmall
}) => {
	return (
		<FormControl sx={{ flex: 1 }} fullWidth size={useSmall ? 'small' : null}>
			<InputLabel id='notification-update-type-select-label'>
				{t('notifications:updateType')}
			</InputLabel>
			<Select
				labelId='notification-update-type-select-label'
				label={t('notifications:updateType')}
				id='notification-update-type-select'
				variant='outlined'
				value={selectedUpdateType}
				onChange={(e) => {
					onChangeSelection(e.target.value);
				}}
				renderValue={(updateType) => {
					return capitalCase(t(`notifications:${updateType?.name}`));
				}}
			>
				{notificationUpdateTypes.map((updateType) => {
					return (
						<MenuItem key={updateType.id} value={updateType}>
							<Typography>{capitalCase(t(updateType.name))}</Typography>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

NotificationUpdateTypeSelect.propTypes = {
	selectedUpdateType: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChangeSelection: PropTypes.func,
	t: PropTypes.func,
	useSmall: PropTypes.bool
};

export default withTranslation(['notifications', 'common'])(
	NotificationUpdateTypeSelect
);
